<template>
  <view>
    <view class="title" :style="{height:height}">
      <view class="title-name" :style="{fontSize:size}" v-if="isTitle">
        {{title}}
      </view>
      <view class="">
        <view v-for="(item,index) in dateRecord" :key="index" class="date-style"
          :class="activeValue === item.time? 'red':''" @click="switchTime(item.time)">
          {{item.value}}
        </view>
        <el-date-picker v-model="createTime" type="daterange" value-format="YYYY-MM-DD"
          :disabled-date="disabledDate" @change="change" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" />
      </view>
    </view>
  </view>
</template>
<script setup>
import { onMounted, reactive, toRefs } from 'vue'
import { dateList } from '../enums/index.js'
import { getDate } from "@/filters/index.js";
const props = defineProps({
  isTitle: {
    type: Boolean,
    default: true
  },
  //title
  title: {
    type: String,
    default: '趋势图'
  },
  //标题高度
  height: {
    type: String,
    default: '67px'
  },
  defaultValue: {
    type: String,
    default: '7'
  },
  //标题大小
  size: {
    type: String,
    default: '16px'
  }
})
let { defaultValue } = props
const dateRecord = dateList
//开始、结束时间
const state = reactive({
  createTime: [],
  activeValue: null
})
const { createTime, activeValue } = toRefs(state)
const emits = defineEmits(['dateTime'])
//时间区间切换
const switchTime = (val) => {
  // if (state.activeValue === val) return
  state.activeValue = val
  state.createTime = []
  getDateTime(val)
}
const getDateTime = (val) => {
  const { start, end } = getDate(val, '1')
  state.createTime = [start, end]
  emits('dateTime', { startTime: start, endTime: end })
}
//只能选择过往时间
const disabledDate = (time) => {
  let max = new Date(new Date());
  return time.getTime() > max
}
//自定义时间
const change = () => {
  if (state.createTime) {
    state.activeValue = null
    state.startTime = state.createTime[0]
    state.endTime = state.createTime[1]
    emits('dateTime', { startTime: state.startTime, endTime: state.endTime })
  } else {
    getDateTime(defaultValue)
    state.activeValue = defaultValue
  }
}
onMounted(() => {
  state.activeValue = defaultValue
  getDateTime(defaultValue)
})
defineExpose({ switchTime })
</script>
<style lang="scss" scoped>
.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e8eb;
  background: #fff;
  padding: 0px 20px;
  .title-name {
    font-weight: bold;
    color: #000;
  }
}
.date-style {
  font-weight: 400;
  font-size: 14px;
  color: #6a7484;
  margin-right: 34px;
  cursor: pointer;
}
</style>