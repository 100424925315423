<!--
 * @Description: 表格组件
 * @Author: zjc
 * @Date: 2021-12-07 11:49:02
 * @LastEditTime: 2024-06-20 17:45:28
 * @LastEditors: zjc
-->
<template>
  <div class="table-style">
    <el-table class="demo-form-inline" :key="toggleIndex" :row-id="tableOption.rowKey" id='table'
      :header-cell-style="{'text-align': tableOption.align || 'center'}"
      :show-header="tableOption?tableOption.header:'true'" :expand-row-keys="tableOption.expand"
      :default-sort="tableOption.sort?{prop: tableOption.props, order: tableOption.order}:{}"
      :tree-props="tableOption.tree?tableOption.treeLable:treeConfig"
      :row-key="tableOption.getRowKey" :default-expand-all="tableOption.defaultAll"
      :data="tableData" ref="tableLayout" :empty-text="emptyText" :size="size" :height="height"
      :max-height="maxHeight" @row-click="handleRowClick" @row-dblclick="handleRowDblclick"
      @sort-change="sortChange" @selection-change="handleSelectionChange" @select="selectChange"
      v-loading="tableLoading" element-loading-text="加载中" :row-style="rowStyle"
      :cell-class-name="tableRowClassName" table-layout="auto">
      <el-table-column v-if="tableOption.index" type="index" label="序号" width="70" align="center">
      </el-table-column>
      <template v-if="tableOption.selection">
        <el-table-column :selectable="tableOption.selectable"
          :reserve-selection="tableOption.reserve" type="selection" width="40" align="center">
        </el-table-column>
      </template>
      <template v-for="(item) in tableList">
        <el-table-column :key="item.label" v-if="item.tableProp" :tree-node="item.tree || false"
          :prop="item.prop" :sortable="item.sort" :label="item.label"
          :show-overflow-tooltip="item.tooltip?true:false" :align="item.align || 'center'"
          :width="item.width" :min-width="item.minWidth">
          <template #header v-if="item.header">
            <slot :name="item.headerProp"></slot>
          </template>
          <template #default="scope">
            <slot :row="scope.row" :$index="scope.$index" v-if="item.slot" :name="item.prop"></slot>
            <span v-else>{{scope.row[item.prop]}}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-if="tableOption.operationData" :prop="tableOption.operationData.prop"
        :label="tableOption.operationData.label" :fixed="tableOption.operationData.fixed"
        :align="tableOption.operationData.align || 'center'"
        :width="tableOption.operationData.width" :min-width="tableOption.operationData.minWidth">
        <template #default="scope">
          <div @click.stop
            :style="{'text-align': tableOption.operationData.align || 'left', 'margin-left': tableOption.operationData.prop =='operation'? '-5px': ''}">
            <slot :row="scope.row" :$index="scope.$index" :name="tableOption.operationData.prop">
            </slot>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>
<script setup>
import { reactive, toRefs, computed, ref, nextTick, onUnmounted } from 'vue'
import { commonStore } from '@/store/common'
/**
* @param {Array} tableData 表格渲染数据
* @param {Number} maxHeight 表格最大高度
* @param {Number} height 表格高度
* @param {emptyText} String 无数据内容
* @param {String} 表格尺寸
* @param {Object} 表格配置项
*        sort 是否需要排序
*          prop 默认排序字段
*          order 排序类型 升序 降序
*        tree 是否树形表格
*        selection 是否为多选表格
*        align 对齐方式
* 
*        column  单元格配置
*          tableProp 是否显示单元格
*          tooltip 超出是否...
*          prop 表格字段
*          label 表格名称
*          slot 是否插槽
*          header 是否显示头部
*          sort 排序 默认 custom
*          width 宽度
*          minWidth 最小宽度
*          search 是否是搜索条件 为true hide也要为true
*          hide 不显示在表格 可能是搜索条件定义
*/
const store = commonStore()
const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  maxHeight: {
    type: Number,
  },
  height: {
    type: Number
  },
  emptyText: {
    type: String,
    default: '暂无数据'
  },
  tableOption: {
    type: Object,
  },
  size: {
    type: String
  }
})
const state = reactive({
  toggleIndex: 0,
  treeConfig: { children: 'children', hasChildren: 'hasChildren' },
  selectList: [],//选中数据
})
const tableLayout = ref(null)
const { toggleIndex, treeConfig, selectList } = toRefs(state)
const checkTrue = (item) => {
  tableLayout.value.toggleRowSelection(item, true)
}
const tableList = computed(() => props.tableOption.column.filter((item) => !item.hasOwnProperty('hide')))

const tableLoading = computed(() => store.getTableLoading)

const emit = defineEmits(['handleRowClick', 'handleRowDblclick', 'handleSelectionChange', 'selectChange', 'sortChange'])
// 单击表格
const handleRowClick = (row, column, cell, event) => {
  emit('handleRowClick', row, column, cell, event)
}
// 双击表格
const handleRowDblclick = (row, column, cell, event) => {
  emit('handleRowDblclick', row, column, cell, event)
}
//多选回调
const handleSelectionChange = (selection) => {
  state.selectList = Array.from(selection, ({ index }) => index)
  emit('handleSelectionChange', selection)
}
const selectChange = (selection, row) => {
  emit('selectChange', selection, row)
}
//选中改变样式
const rowStyle = ({ row, rowIndex }) => {
  for (const i of state.selectList) {
    if (i === rowIndex) return { backgroundColor: '#E6F7FF' }
  }
}

const tableRowClassName = ({ row, rowIndex }) => {
  row.index = rowIndex;
}
// 排序回调
const sortChange = ({ column, prop, order }) => {
  emit('sortChange', { column, prop, order })
}
// 初始化
const initTable = () => {
  state.toggleIndex++
  nextTick(() => {
    tableLayout.value.doLayout()
  })
}
const toggleRowSelection = (rows) => {
  if (rows) {
    rows.forEach(row => {
      nextTick(() => {
        tableLayout.value.toggleRowSelection(row, true);
      })

    });
  }
}
const clearSort = () => {
  nextTick(() => {
    tableLayout.value.clearSort()
  })
}
const clearSelection = () => {
  tableLayout.value.clearSelection();
}

// 抛出table ref, 便于扩展 1.选择列表可以实现单选
const getTableRef = () => {
  return tableLayout.value;
}

defineExpose({ clearSelection, clearSort, toggleRowSelection, initTable, getTableRef, checkTrue })
onUnmounted(() => {
  state.toggleIndex = 0
})
</script>
<style lang="scss" scoped>
.table-style {
  :deep(.el-table thead tr) {
    height: 46px;
    background: #fafafa;
  }

  :deep(.el-table--striped .el-table__body tr.el-table__row--striped td),
  :deep(.el-table th) {
    background: #fafafa;
    color: var(--text-color);
    font-weight: 500;
  }
  :deep(.el-table) {
    color: var(--text-color);
  }
  :deep(.el-table td.el-table__cell),
  :deep(.el-table th.el-table__cell.is-leaf) {
    border-bottom: 1px solid #e7e7e7;
  }
  :deep(.el-dropdown-link) {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    margin-left: 12px;
    color: var(--color-primary);
  }
  :deep(.el-table td.el-table__cell div) {
    img {
      vertical-align: middle;
    }
  }
}
</style>
<style>
.el-popper {
  max-width: 800px;
}
</style>
<style lang="scss">
.el-table__body-wrapper {
  margin-top: 1px !important;
  .el-scrollbar__wrap {
    // overflow: hidden;
  }
}
</style>