/*
 * @Description: 
 * @Author: ym
 * @Date: 2023-07-10 10:15:12
 * @LastEditTime: 2024-12-02 14:59:23
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import request from '@/utils/request'

const getAddRole = (params) => request('post', '/org/role/add', params, { requestType: 'JSON' }) //新增角色
const getAddUpdate = (params) => request('post', '/org/role/update', params, { requestType: 'JSON' }) //修改角色
const getRole = (params) => request('get', '/org/role/page', params) //角色列表
const getRoleInfo = (roleId) => request('get', `/org/role/info/${roleId}`) //角色详情
const getRoleDelete = (params) => request('post', '/org/role/delete', params) //删除角色
const getRoleMember = (roleId, params) => request('get', `/org/role/member/${roleId}`, params ) // 当前角色的成员
const getRoleMemberPage = (params) => request('get', `/org/role/member/page`, params, { loading: true }) // 当前角色的成员（分页）
const delRoleMember = (params) => request('post', `/org/role/member-remove`, params, { requestType: 'array' }) //  移除当前角色的成员
const addRoleMember = (params) => request('post', `/org/role/member-add`, params, { requestType: 'array' }) //  添加当前角色的成员
const adminSwitch = (params) => request('post', `/org/role/admin-switch`, params) //  移交组织所有者角色权限


const myRole = (params) => request('get', '/org/oms/my-role', params) //获取我的角色列表
const permissionTree = () => request('get', '/org/permission/user-permission/tree') //获取菜单树


const getOrgInfo = (orgId) => request('get', `/org/org/info/${orgId}`) //获取当前机构信息
const getOrgUpdate = (params) => request('post', '/org/org/update', params) //修改机构信息

const getlibrary = (params) => request('get', '/library/library/page', params) //图书馆列表
const addlibrary = (params) => request('post', '/library/library/create', params) //创建图书馆
const updatelibrary = (params) => request('post', '/library/library/update', params) //更新图书馆
const updatelibraryStatus = (params) => request('post', '/library/library/status-change', params) //图书馆状态修改
const getLibraryInfo = (libraryId) => request('get', `/library/library/info/${libraryId}`) //图书馆详情

const getAdminAdd = (params) => request('post', '/library/library/admin-add', params) //添加图书馆管理员
const getAdminRemove = (params) => request('post', '/library/library/admin-remove', params) //移除图书馆管理员

const getAdminList = (libraryId) => request('get', `/library/library/admin-list/${libraryId}`) //图书馆管理员列表

const openUnits = (id) => request('get', `/library/library/open-units/${id}`)  // 书馆开发部门列表
const openMembers = (id) => request('get', `/library/library/open-members/${id}`) // 书馆开放用户列表
const updateOpenObject = (params) => request('post', `/library/library/update-open-object`, params, { requestType: 'JSON' }) // 修改开放范围


const getLibraries = () => request('get', `/library/library/admin/libraries`) //获取当前管理的图书馆列表
const getLibrariesv2 = (obj) => request('get', `/library/library/admin/v2/libraries`, obj) //获取当前管理的图书馆列表分页

const addNav = (params) => request('post', '/library/nav/create', params) //添加导航
const updateNav = (params) => request('post', '/library/nav/update', params) //修改导航
const delNav = (params) => request('post', '/library/nav/delete', params) //删除导航
const navQuery = (params) => request('post', '/library/nav/query', params, { loading: true }) //导航列表

const libraryUrl = (params) => request('get', '/dist/account/library-url', params) //获取馆配平台url

const getMemberInfoByPhone = (params) => request('post', '/org/member/find-by-phones', params, { requestType: 'array' }) //根据手机号列表获取成员信息
const updateOther = (params) => request('post', '/library/library/update/other', params) //组织图书馆排序，取消、设为默认图书馆

const importLibraryList = (params) => request('post', '/library/libraryImportRecord/oms/page', params, { loading: true })
const libraryExport = (data) => request('post', '/library/libraryImportRecord/oms/detail/export', data, { responseType: 'blob' })
export const SYSTEM_API = {
  getRole,
  getAddRole,
  getAddUpdate,
  getOrgInfo,
  getRoleInfo,
  getOrgUpdate,
  getlibrary,
  getRoleDelete,
  getRoleMember,
  getRoleMemberPage,
  delRoleMember,
  addRoleMember,
  adminSwitch,
  addlibrary,
  updatelibrary,
  updatelibraryStatus,
  getLibraries,
  getLibraryInfo,
  getAdminAdd,
  getAdminList,
  getAdminRemove,
  openUnits,
  openMembers,
  updateOpenObject,
  addNav,
  updateNav,
  delNav,
  navQuery,
  libraryUrl,
  getMemberInfoByPhone,
  updateOther,
  importLibraryList,
  libraryExport,
  myRole,
  permissionTree,
  getLibrariesv2
}
