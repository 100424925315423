/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-09-13 09:04:58
 * @LastEditTime: 2022-04-12 11:43:40
 * @LastEditors: zjc
 */

import CryptoJS from 'crypto-js'

// /**
//  * 采用AES的ECB模式 https://blog.csdn.net/AiHuanhuan110/article/details/99302114
//  * 第一个参数word是待加密或者解密的字符串；
//  * 第二个参数keyStr是aes加密需要用到的16位字符串的key；
//  *
//  * 如果想对一个js对象加密，需要先把该对象转成json字符串。
//  */
// export default {
//   // 加密
//   encrypt(word, keyStr) {
//     keyStr = keyStr ? keyStr : "gbcloudgbcloudgb"
//     var key = CryptoJS.enc.Utf8.parse(keyStr)
//     var srcs = CryptoJS.enc.Utf8.parse(word)
//     var encrypted = CryptoJS.AES.encrypt(srcs, key, {
//       mode: CryptoJS.mode.ECB,
//       padding: CryptoJS.pad.Pkcs7
//     })
//     return encrypted.toString()
//   },
//   // 解密
//   decrypt(word, keyStr) {
//     keyStr = keyStr ? keyStr : "gbcloudgbcloudgb"
//     var key = CryptoJS.enc.Utf8.parse(keyStr)
//     var decrypt = CryptoJS.AES.decrypt(word, key, {
//       mode: CryptoJS.mode.ECB,
//       padding: CryptoJS.pad.Pkcs7
//     })
//     return CryptoJS.enc.Utf8.stringify(decrypt).toString()
//   }
// }

/**
 * 采用AES的CBC模式
 * 第一个参数word是待加密或者解密的字符串；
 * 第二个参数keyStr是aes加密需要用到的16位字符串的key；
 * 第三个参数是初始化向量 iv。
 */
export default {
  // hash
  MD5 (f) {
    return CryptoJS.MD5(f)
  },
  // 加密
  encrypt (word, keyStr, ivStr) {
    keyStr = keyStr || 'gbcloudgbcloudgb'
    ivStr = ivStr || 'gbcloudgbcloudgb'
    var key = CryptoJS.enc.Utf8.parse(keyStr)
    var iv = CryptoJS.enc.Utf8.parse(ivStr)
    var srcs = CryptoJS.enc.Utf8.parse(word)

    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      block: 128
    })
    return encrypted.toString()
  },
  // 解密
  decrypt (word, keyStr, ivStr) {
    keyStr = keyStr || 'gbcloudgbcloudgb'
    ivStr = ivStr || 'gbcloudgbcloudgb'
    var key = CryptoJS.enc.Utf8.parse(keyStr)
    var iv = CryptoJS.enc.Utf8.parse(ivStr)

    var decrypt = CryptoJS.AES.decrypt(word, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    return decrypt.toString(CryptoJS.enc.Utf8)
  },
  // 随机字符串
  randomString (
    len = 16,
    chars = '0123456789abcdefghiklmnopqrstuvwxyz',
    randomString = ''
  ) {
    for (let i = 0; i < len; i++) {
      const rNum = Math.floor(Math.random() * chars.length);
      randomString += chars.substring(rNum, rNum + 1);
    }
    return randomString
  }
}
