<!--
 * @Description: 
 * @Author: ym
 * @Date: 2023-06-16 15:53:21
 * @LastEditTime: 2024-12-10 16:39:04
 * @LastEditors: zjc
-->
<template>
  <div class="switch-tabs" v-if="dataList.length">
    <div style="position: absolute;right:1px;z-index: 101;top:12px;color:#909399;cursor: pointer;"
      @click="reset">
      <el-icon>
        <Refresh />
      </el-icon>
    </div>
    <div
      style="width:60px;margin-top:8px;text-align: center;position: absolute;left: -6px;z-index: 101;">
      <el-popover placement="bottom-start" ref="popoverRef" :visible="visible" @show="showPop"
        @hide="hidePop" popper-class="class-index" :width="290">
        <div class="search-box" style="min-height:260px;">
          <el-input style="width: 206px" v-model="searchText" clearable placeholder="请输入图书馆名称"
            @clear="clearVal">
            <template #prefix>
              <el-icon class="el-input__icon icons">
                <Search />
              </el-icon>
            </template>
          </el-input>
          <el-button type="text" size="small" @click="searchLibrary">搜索</el-button>

          <div class="library">
            <vxe-table max-height="280" min-height="58" align='left' border="none" ref="popoverRef1"
              :data="search.visibleData" :scroll-y="{enabled: true}" :show-header="false">
              <vxe-column field="libraryName" title="图书馆名称">
                <template #default="{ row }">
                  <div @click="selectLibrary(row)" class="library">
                    {{ row.libraryName}}
                  </div>
                </template>
              </vxe-column>
              <template #empty>
                <span style="color: #909399;">
                  <img style="height:62px;" :src="getAssetUrl('icon/no-text.png')">
                  <p>{{isSearch?'温馨提示:暂无数据，仅展示您有权限的图书馆':'找不到您想要的图书馆?试试搜索功能!'}}</p>
                </span>
              </template>
            </vxe-table>
          </div>
        </div>
        <template #reference>
          <img style="height:24px; width:24px;cursor: pointer;" :src="getAssetUrl('icon/icon.png')"
            @click="visible =!visible" v-click-outside="onClickOutside">
        </template>
      </el-popover>
    </div>
    <div id="tabRef">
      <el-tabs v-model="activeId" type="card" ref="tabRef" class="demo-tabs"
        @tab-click="handleClick">
        <el-tab-pane v-for="item in tabList" :label="item.libraryName" :name="item.libraryId"
          :lazy="true" :key="item.libraryId">
          <template #label>
            <span class="custom-tabs-label" :id="item.libraryId">
              <img
                :src="activeId === item.libraryId?getAssetUrl('icon/icon_library_sel.png'):getAssetUrl('icon/icon_library_nor.png')"
                alt="">
              <span>{{ item.libraryName }}</span>
            </span>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script setup>
import { toRefs, reactive, ref, onMounted, nextTick, unref, computed, onBeforeUnmount, watch } from "vue"
import { COMMON_API } from "@/utils/common";
import { ClickOutside as vClickOutside } from "element-plus";
const emit = defineEmits(['handleClick', 'update:modelValue', 'update:organizationName'])
import { SYSTEM_API } from '@/service/system/index.js'
import { getAssetUrl } from '@/theme/theme.js'
import { getStore, setStore, removeStore } from '@/utils/store'
import { adminStore } from '@/store/user.js'
const useStore = adminStore()
const props = defineProps({
  modelValue: {
    default: null
  },
  organizationName: String,
})
const isSearch = ref(false)
const visible = ref(false)
const popoverRef = ref(null)
const popoverRef1 = ref(null)
const tabRef = ref(null)
const indexs = ref(0)

const state = ({
  activeId: Number(props.modelValue)
})
const searchText = ref(null)
const search = reactive({
  visibleData: [],//列表展示数据
})
const dataList = computed(() => useStore.getLibraryLists)
const tabList = ref([])
onMounted(async () => {
  indexs.value = 0
  if (dataList.value && dataList.value.length) {
    tabList.value = dataList.value
    state.activeId = getStore({ name: 'libraryId' }) ? getStore({ name: 'libraryId' }) : tabList.value[0].libraryId
    setStore({ name: 'libraryId', content: state.activeId })
    setStore({ name: 'libraryName', content: getStore({ name: 'libraryName' }) ? getStore({ name: 'libraryName' }) : tabList.value[0].libraryName })
    updateModel(state.activeId, getNameById(tabList.value, state.activeId));
    nextTick(() => {
      let className1 = document.querySelector('.el-tabs__nav-prev')
      let className2 = document.querySelector('.el-tabs__nav-next')
      className1.addEventListener('click', function (e) {
        indexs.value = 0
      })
      className2.addEventListener('click', function (e) {
        let className = document.querySelector('.el-tabs__nav-next.is-disabled')
        if (className) {
          if (indexs.value > 0) {
            visible.value = true
          }
          indexs.value++
        } else {
          indexs.value = 0
        }
      })
    })
  } else {
    setStore({ name: 'libraryId', content: null })
    // emit("update:modelValue", null)
    updateModel(null, null)
  }

  window.addEventListener('setItemEvent', function (e) {
    nextTick(() => {
      if (e.key === 'libraryList') {
        dataList.value = useStore.getLibraryList()
        fetchGroupList(dataList.value)
      }
    })

  })
})
const onClickOutside = (e) => {
  if (
    visible.value &&
    !unref(popoverRef).popperRef.contentRef.contains(e.target)
  ) {
    visible.value = false;
  }
}
const getNameById = (arr, id) => {
  for (const item of arr) {
    if (item.libraryId == id) {
      return item.libraryName;
    }
  }
}


const handleClick = (tab) => {
  console.log(tab, 'tab')
  state.activeId = tab.props.name
  setStore({ name: 'libraryId', content: tab.props.name })
  setStore({ name: 'libraryName', content: tab.props.label })
  updateModel(tab.props.name, tab.props.label)
  emit('handleClick', tab.props)
}


const updateModel = (id, name) => {
  emit("update:modelValue", id)
  emit("update:organizationName", name)
}
//选择图书馆
const selectLibrary = (row) => {
  tabList.value = []
  tabList.value = [{
    libraryName: row.libraryName,
    libraryId: row.libraryId
  }]
  visible.value = false
  state.activeId = row.libraryId
  setStore({ name: 'libraryId', content: row.libraryId })
  setStore({ name: 'libraryName', content: row.libraryName })
  updateModel(row.libraryId, row.libraryName)
  emit('handleClick', row)
}

//弹出层隐藏
const hidePop = () => {
  search.visibleData = []
  searchText.value = null
  isSearch.value = false
}
//清除数据
const clearVal = () => {
  searchText.value = null
  search.visibleData = []
}
//搜索图书馆
const searchLibrary = async () => {
  const { data } = await SYSTEM_API.getLibrariesv2({ size: 30, current: 1, libraryName: searchText.value })
  search.visibleData = data.records
  isSearch.value = true
}
const reset = async () => {
  const { data } = await SYSTEM_API.getLibrariesv2({ size: 30, current: 1, isEnabled: 1 })

  tabList.value = data.records
  state.activeId = tabList.value[0].libraryId
  setStore({ name: 'libraryId', content: state.activeId })
  setStore({ name: 'libraryName', content: tabList.value[0].libraryName })
  updateModel(state.activeId, getNameById(tabList.value, state.activeId));
  emit('handleClick', tabList.value[0])
  visible.value = false
  nextTick(() => {
    handleClick({ props: { name: state.activeId, label: tabList.value[0].libraryName } })
    let className1 = document.querySelector('.el-tabs__nav-prev')
    let className2 = document.querySelector('.el-tabs__nav-next')
    className1.addEventListener('click', function (e) {
      indexs.value = 0
    })
    className2.addEventListener('click', function (e) {
      e.preventDefault()
      let className = document.querySelector('.el-tabs__nav-next.is-disabled')
      if (className) {
        if (indexs.value > 0) {
          visible.value = true
        }
        indexs.value++
      } else {
        indexs.value = 0
      }
    })
  })
}
defineExpose({})

const { activeId, show } = toRefs(state)
</script>
<style>
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  position: relative;
  background-color: #fff;
}
.class-index {
  z-index: 100 !important;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.library:hover {
  color: var(--color-primary);
}
.select-library {
  color: var(--color-primary);
}
.icons {
  cursor: pointer;
}
.library {
  :deep(.vxe-table--render-default .vxe-body--column.col--ellipsis) {
    height: 40px !important;
    line-height: 40px !important;
  }
  :deep(.vxe-table--render-default .vxe-table--empty-content) {
    width: 70% !important;
  }
}
.switch-tabs {
  position: relative;
  :deep(.el-tabs__header) {
    margin: 0 0 20px;
  }
  :deep(.el-tabs__item) {
    color: #000000a6;
    padding: 0px 13px;
  }
  :deep(.el-tabs__nav-wrap.is-scrollable) {
    padding: 0 68px 0px 0px;
    box-sizing: border-box;
  }
  :deep(.el-tabs__nav-prev) {
    right: 41px;
    left: auto;
  }
  :deep(.el-tabs__nav-next) {
    right: 23px;
  }
  :deep(.el-tabs__nav-scroll) {
    margin-left: 50px;
  }
  :deep(
      .el-tabs--top.el-tabs--card
        > .el-tabs__header
        .el-tabs__item:nth-child(2n)
    ) {
    padding-left: 13px;
  }
  :deep(
      .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child
    ) {
    padding-right: 13px;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item.is-active) {
    color: var(--color-primary);
    font-weight: 500;
    border-radius: 8px 0px 0 0;
    background: #fff;
  }
  :deep(
      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:nth-child(1)
    ) {
    border-radius: 8px 0px 0 0 !important;
  }
  :deep(
      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:nth-child(1n)
    ) {
    border-radius: 0px 8px 0 0;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border-radius: 8px 8px 0 0;
  }
}
.custom-tabs-label {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
}
.search-box {
}
</style>