/*
 * @Description: 
 * @Author: zjc
 * @Date: 2023-08-23 17:25:42
 * @LastEditTime: 2024-08-23 13:36:04
 * @LastEditors: 杨明 ym@geeboo.cn
 */

import { adminStore } from '@/store/user.js'

export function authDirective (app) {
  // 单个权限验证（v-auth="xxx"）
  app.directive('auth', {
    mounted (el, binding) {
      const stores = adminStore().getAuthCode;
      if (!stores.some((v) => v === binding.value)) el.parentNode.removeChild(el);
    },
  });
}
export function isAuth (code) {
  const stores = adminStore().getAuthCode;
  return stores.includes(code) ? false : true
}
export const authTip = '没有权限'