/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:45:14
 * @LastEditTime: 2024-11-05 11:12:30
 * @LastEditors: zjc
 */
import { createApp } from 'vue'
import 'virtual:svg-icons-register'
import { createPinia } from 'pinia'
import App from './app.vue'
import router from '@/router/index.js'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/theme/theme-all.css'
import 'element-plus/dist/index.css'
import '@/styles/global.scss'
import '@/permissions'
import '@/utils/globalComponent'
import '../icons/index.js'
import '@/permissions/index.js';

import { authDirective } from '@/directive/index.js'
import uploader from 'vue-simple-uploader'
import SvgIcon from '@/components/SvgIcon/index.vue'
import { setupComponent } from '@/utils/globalComponent'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Directives from "@/utils/directives"
import { Cascader, Popup, showToast, Picker, Field, CellGroup, List, Swipe, SwipeItem, Button, Overlay, Dialog, DatePicker, Icon, Progress, Tabs, Tab, TextEllipsis } from 'vant';
// 引入组件样式
import 'vant/lib/index.css';
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'


// if (typeof window.global === 'undefined') {
//   window.global = window;
// }

const create = createApp(App)

// 引入vant组件
create.use(Cascader);
create.use(Picker);
create.use(Field);
create.use(CellGroup);
create.use(Popup);
create.use(List);
create.use(Swipe);
create.use(SwipeItem);
create.use(Button);
create.use(Overlay);
create.use(Dialog);
create.use(DatePicker);
create.use(Icon);
create.use(Progress);
create.use(Tab);
create.use(Tabs);
create.use(TextEllipsis);


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  create.component(key, component)
}
setupComponent(create)
authDirective(create)
import { dispatchEventStroage } from '@/utils/watchLocalStorage.js'

// 加入elementUI 全局提示
// Vue.prototype.$ok = (message, title = '消息') => {
//   ElementUI.Notification({
//     type: 'success',
//     title: title,
//     message: message,
//     duration: 2000,
//   })
// }
// Vue.prototype.$fail = (message, title = "错误") => {
//   ElementUI.Notification({
//     type: 'error',
//     title: title,
//     message: message,
//     duration: 2000,
//   })
// }

create.use(ElementPlus, { locale: zhCn, })
  .use(VXETable)
  .use(Directives)
  .use(router)
  .use(dispatchEventStroage)
  .use(uploader)
  .use(createPinia())
  .component('svg-icon', SvgIcon)
  .mount('#app')
