/*
 * @Description: 
 * @Author: zjc
 * @Date: 2023-04-23 13:49:25
 * @LastEditTime: 2023-05-31 09:39:47
 * @LastEditors: ym
 */

//图书统计
const bookEnum = [
  { icon: new URL('../../../assets/img/statistics/icon_book_borrow.png', import.meta.url).href, title: '总借阅人次', num: 0, tooltip: false, isCount: true, prop: 'totalBorrowCnt' },
  { icon: new URL('../../../assets/img/statistics/icon_book_read.png', import.meta.url).href, title: '总阅读人数', num: 0, tooltip: false, isCount: true, prop: 'totalReadCnt' },
  { icon: new URL('../../../assets/img/statistics/icon_book_time.png', import.meta.url).href, title: '总阅读时长', num: 2, tooltip: false, isCount: false, prop: 'totalReadDuration' },
  { icon: new URL('../../../assets/img/statistics/icon_book_notes.png', import.meta.url).href, title: '当前总笔记数', tooltip: true, num: 0, isCount: true, prop: 'totalNoteCnt' },
]
//课程统计
const courseEnum = [
  { icon: new URL('../../../assets/img/library/statistics/icon50_peoplenumber.png', import.meta.url).href, title: '总学习人数', num: 0, tooltip: false, isCount: true, prop: 'learnedUserCnt' },
  // { icon: new URL('../../../assets/img/statistics/icon_book_read.png', import.meta.url).href, title: '总学习人次（次）', num: 1200 },
  { icon: new URL('../../../assets/img/library/statistics/icon50_duration.png', import.meta.url).href, title: '总学习时长', num: 2, isCount: false, tooltip: false, prop: 'studyTimeCount' },
]
//共读统计
const readEnum = [
  { icon: new URL('../../../assets/img/library/statistics/icon50_peoplenumber.png', import.meta.url).href, title: '总共读人数', num: 0, tooltip: false, isCount: true, prop: 'learnedUserCnt' },
  { icon: new URL('../../../assets/img/library/statistics/icon50_duration.png', import.meta.url).href, title: '总共读时长', num: 2, tooltip: false, isCount: false, prop: 'studyTimeCount' },
  // { icon: new URL('../../../assets/img/statistics/icon_book_notes.png', import.meta.url).href, title: '当前总笔记数（条）', num: 0, isCount: true, prop: 'totalNoteCnt' },
]
//学习资料
const learningEnum = [
  { icon: new URL('../../../assets/img/library/statistics/icon50_peoplenumber.png', import.meta.url).href, title: '总阅读人数', num: 0, tooltip: false, isCount: true, prop: 'learnedUserCnt' },
  { icon: new URL('../../../assets/img/library/statistics/icon50_duration.png', import.meta.url).href, title: '总阅读时长', num: 2, tooltip: false, isCount: false, prop: 'studyTimeCount' },
  { icon: new URL('../../../assets/img/library/statistics/icon50_readtime.png', import.meta.url).href, title: '当前总笔记数', tooltip: true, num: 0, isCount: true, prop: 'noteCntCount' },
]
//趋势时间区间
const dateList = [
  { value: '近7日', time: '7' },
  { value: '近15日', time: '15' },
  { value: '近30日', time: '30' }
]
const orgTypeList = [
  { label: '', name: '1' },
  { label: '下级党组织概况', name: '2' },
]
export {
  bookEnum,
  courseEnum,
  learningEnum,
  orgTypeList,
  dateList,
  readEnum
}
