<!--
 * @Description: 菜单按钮、搜索条件隐藏，字段显示隐藏
 * @Author: zjc
 * @Date: 2021-12-08 15:16:23
 * @LastEditTime: 2023-05-26 13:45:03
 * @LastEditors: ym
-->
<template>
  <div>
    <div class="menu-flex">
      <div class="menu-flex__left">
        <slot name="menuLeft"></slot>
      </div>
      <div class="menu-flex__right" v-if="isMenuRight">
        <slot name="menuRight"></slot>

        <el-tooltip v-if="fullScreen" class="item" effect="dark"
          :content="contentScreen?'退出全屏':'全屏'" placement="top">
          <el-icon @click="setFullscreen">
            <FullScreen />
          </el-icon>
          <!-- <i class="el-icon-full-screen" ></i> -->
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-icon @click="refresh">
            <RefreshRight />
          </el-icon>
          <!-- <i class="el-icon-refresh" @click="refresh"></i> -->
        </el-tooltip>
        <el-tooltip v-if="isColumn" class="item" effect="dark" content="列设置" placement="top">
          <el-dropdown :hide-on-click="false" trigger="click" placement="bottom"
            @visible-change="showOrHide">
            <el-icon>
              <Operation />
            </el-icon>
            <!-- <i class="el-icon-s-operation"></i> -->
            <template #dropdown>
              <el-dropdown-menu>
                <table-prop ref="tableProps" :tablePropList="tableOption.column"
                  @changeProp="changeProp">
                </table-prop>
              </el-dropdown-menu>
            </template>

          </el-dropdown>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script setup>
import tableProp from './tableProps.vue'
import { ref, computed } from 'vue'
import { commonStore } from '@/store/common'
/**
   * @param {Object} tableOption 需要动态显示列表字段的对象
   * @param {Boolean} fullScreen   是否显示全屏
  */

const props = defineProps({
  tableOption: Object,
  fullScreen: {
    type: Boolean,
    default: true
  },
  isColumn: {
    type: Boolean,
    default: false
  },
  isMenuRight: {
    type: Boolean,
    default: true
  }
})
const isFull = ref(false)
const tableProps = ref(null)
const emit = defineEmits(['redesign', 'refresh'])
const store = commonStore()
const contentScreen = computed(() => store.getContentScreen)
const showOrHide = (val) => {
  if (val) tableProps.value.init()
}
const changeProp = (val) => {
  emit('redesign', val)
}
const refresh = () => {
  emit('refresh')
}
const setFullscreen = () => {
  store.CONTENT_FULLSCREN()
  isFull.value = contentScreen
}
</script>
<style lang="scss" scoped>
.menu-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;

  &__left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__right {
    flex: 0 0 390px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    .el-button + .el-button {
      margin-right: 12px;
    }
  }
}
.menu-flex__right {
  i {
    padding: 0px 8px;
    font-size: 32px;
    color: #9ba6ba;
    cursor: pointer;
  }
}
</style>