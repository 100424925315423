<!--
 * @Description: 字典项
 * @Author: zjc
 * @Date: 2023-06-19 10:51:39
 * @LastEditTime: 2023-06-19 19:41:09
 * @LastEditors: zjc
-->
<template>
  <div>
    <template v-for="(item, index) in props.options">
      <template v-if="values.includes(item.value)">
        <div class="dict" :key="index">
          <span class="circle" v-if="item.color" :style="{'background-color': item.color}"></span>
          <span>{{item.label}}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { COMMON_API } from '@/utils/common'
const props = defineProps({
  // 数据
  options: {
    type: Array,
    default: null,
  },
  // 当前的值
  value: [Number, String, Array],
});

const values = computed(() => {
  if (!COMMON_API.validatenull(props.value)) {
    return Array.isArray(props.value) ? props.value : [props.value];
  } else {
    return [];
  }
});
</script>

<style lang="scss" scoped>
.dict {
  span {
    vertical-align: middle;
  }
  .circle {
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 6px;
  }
}
</style>
