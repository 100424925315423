<template>
  <div>
    <el-dialog modal-append-to-body :title="'选择图书馆'" width="600px" @closed="closeDialog"
      append-to-body :close-on-click-modal="false" top="5vh" v-model="showLibrary">
      <el-input v-model="searchText" @input="searchLibrary" clearable
        style="margin-bottom:20px;height:40px" placeholder="请输入图书馆名称" @clear="clearVal">
        <template #prefix>
          <el-icon class="el-input__icon icons">
            <Search />
          </el-icon>
        </template>
      </el-input>
      <div class="library" v-if="!linkUrl">
        <vxe-table max-height="400" min-height="58" align='left' border="none" ref="popoverRef1"
          :data="libraryList" :scroll-y="{enabled: true}" :show-header="false">
          <vxe-column field="libraryName" title="图书馆名称" show-overflow>
            <template #default="{ row }">
              <div class="library-list" :class="row.libraryId === state.activeValue?'selectVal':''"
                @click="selectLibrary(row)">
                <div class="library-item">
                  <img :src="row.libraryLogo?row.libraryLogo:defaultLibrary" class="logo" alt="">
                  <div class="name">{{ row.libraryName }}</div>
                </div>
              </div>
            </template>
          </vxe-column>
          <template #empty>
            <span style="color: #909399;">
              <img style="height:51px; width:80px;" :src="getAssetUrl('icon/noData.png')">
              <p>暂无图书馆</p>
            </span>
          </template>
        </vxe-table>
        <!-- <div class="library-list" v-for="item in libraryList" :key="item.libraryId"
          :class="item.libraryId === state.activeValue?'selectVal':''" @click="selectLibrary(item)">
          <div class="library-item">
            <img :src="item.libraryLogo?item.libraryLogo:defaultLibrary" class="logo" alt="">
            <div class="name">{{ item.libraryName }}</div>
          </div>
        </div>
        <div class="empty" v-if="COMMON_API.validatenull(libraryList)">
          <div class="empty_text">暂无图书馆</div>
        </div> -->
      </div>
      <div v-else class="library">
        <div class="library-list">
          <div class="library-item">
            <img :src="state.libraryData.libraryLogo?state.libraryData.libraryLogo:defaultLibrary"
              class="logo" alt="">
            <div class="name">{{ state.libraryData.libraryName }}</div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer" v-if="!linkUrl">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="save" :loading="loading">确定</el-button>
        </span>
        <span v-else class="dialog-footer">
          <el-button @click="openDialog(linkUrl)">继续馆配</el-button>
          <el-button type="primary" @click="completeLibrary">已完成馆配</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, ref, computed } from 'vue'
import { setStore, getStore } from '@/utils/store'
import { useRoute, useRouter } from 'vue-router'
import useJump from '@/hooks/useCommon.js'
import { adminStore } from '@/store/user.js'
import { COMMON_API } from "@/utils/common";
const useStore = adminStore()
import { SYSTEM_API } from '@/service/system/index.js'
import { getAssetUrl } from '@/theme/theme.js'
import { defaultLibrary } from '@/enums/default.js'
import usePage from '@/hooks/usePage'
import useMsg from '@/hooks/useMsg'
const showLibrary = ref(false)
const loading = ref(false)
const listLoading = ref(false)
const libraryName = ref(null)
const linkUrl = ref(null)
const { pageData, defaultPage } = usePage()
const { jumpPage } = useJump()
const route = useRoute()
const router = useRouter()
const searchText = ref(null)
const state = reactive({
  pageTotal: 0,
  activeValue: null,
  libraryData: {},
})
let bookList = computed(() => useStore.getLibraryList)
let libraryList = ref([])
//初始化
const init = () => {
  showLibrary.value = true
  // getlibrary()
  libraryList.value = bookList.value
}
//重置
const reset = () => {
  defaultPage()
  libraryName.value = null
  nextTick(() => {
    getlibrary()
  })
}
//搜索
const search = () => {
  getlibrary()
}
//清除数据
const clearVal = () => {
  searchText.value = null
  libraryList.value = bookList.value
}
//搜索图书馆
const searchLibrary = () => {
  if (!searchText.value) {
    libraryList.value = bookList.value
    return
  }
  let dataList1 = []
  for (let i of bookList.value) {
    if (i.libraryName.indexOf(searchText.value) > -1) {
      dataList1.push({ ...i })
    }
  }
  libraryList.value = dataList1
}
// 获取图书馆列表
const getlibrary = async () => {
  try {
    listLoading.value = true
    const { data } = await SYSTEM_API.getlibrary({ ...pageData, libraryName: libraryName.value, isEnabled: 1 })
    state.bookList = data.records
    state.pageTotal = data.total
  } finally {
    listLoading.value = false
  }
}

const changePage = (val) => {
  pageData.current = val
  getlibrary()
}
//选中图书馆
const selectLibrary = (item) => {
  if (item.libraryId === state.activeValue) {
    state.activeValue = null
    state.libraryData = {}
  } else {
    state.activeValue = item.libraryId
    state.libraryData = item
  }
}
const handleSizeChange = (val) => {
  pageData.current = 1
  pageData.size = val
  getlibrary()
}
//关闭弹窗
const closeDialog = () => {
  searchText.value = null
  libraryList.value = bookList.value
  showLibrary.value = false
  state.activeValue = null
  state.libraryData = {}
  linkUrl.value = null
}

//保存
const save = async () => {
  if (!state.activeValue) return useMsg().useNotification({ msg: '请选择图书馆', type: 'error' })
  try {
    loading.value = true
    const { data } = await SYSTEM_API.libraryUrl({ libraryId: state.activeValue })
    setTimeout(() => {
      linkUrl.value = data
      openDialog(data)
    }, 500)
  } finally {
    loading.value = false
  }
}
//打开窗口
const openDialog = (link) => {
  var a = document.createElement('a');
  a.setAttribute('href', link);
  a.setAttribute('target', '_blank');
  a.setAttribute('id', 'link');
  // 防止反复添加
  if (!document.getElementById('link')) document.body.appendChild(a);
  a.click();
}
//完成馆配
const completeLibrary = () => {
  setStore({ name: 'libraryId', content: state.activeValue })
  if (route.path === '/books/bookList/index') {
    jumpPage(`/transfer?transfer=/books/bookList/index?libraryId=${state.activeValue}`)
    router.go(0)
  } else {
    jumpPage(`/books/bookList/index?libraryId=${state.activeValue}`)
  }
  closeDialog()
  useStore.setMenuUrl('/books/bookList/index')
}

defineExpose({ init })
</script>
<style lang="scss" scoped>
.library {
  // max-height: 400px;
  :deep(.vxe-table--render-default .vxe-body--column.col--ellipsis) {
    height: 64px !important;
  }
  :deep(
      .vxe-table--render-default .vxe-body--column.col--ellipsis > .vxe-cell
    ) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  overflow: auto;
  .library-list {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #e8ebf0;
    border-radius: 4px;
    .library-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      .name {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        color: #09172f;
      }
      .logo {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        margin-right: 6px;
      }
    }
  }
}
.selectVal {
  background: #ebf4ff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
</style>
