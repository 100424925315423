<!--
 * @Author: 杨明 ym@geeboo.cn
 * @Date: 2023-10-27 15:32:18
 * @LastEditors: 杨明 ym@geeboo.cn
 * @LastEditTime: 2024-08-23 13:14:13
 * @FilePath: \zglibrary-pc-ui\src\viewsMobile\layout\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="mobile-index-container">
      <router-view v-slot="{ Component, route }">
      <!-- <keep-alive  :include="[...state.visitedViewPaths]">
         <component 
              :is="Component"/>
      </keep-alive> -->
         <keep-alive :include="caches">
            <component :is="Component" :key="route.path" v-if="route.meta.keepAlive" />
         </keep-alive>
         <component :is="Component" :key="route.path" v-if="!route.meta.keepAlive" />
      </router-view>
  </div>

</template>
<script setup>
import { reactive } from "vue";
import { RouterView } from 'vue-router'
import useRouteCache from '@/hooks/useRouteCache'
const { caches } = useRouteCache()
const state = reactive({
     visitedViewPaths: ['integralDetail']
})
  
</script>
<style lang="scss" scoped>

</style>