<!--
 * @Description: 菜单按钮、搜索条件隐藏，字段显示隐藏
 * @Author: zjc
 * @Date: 2021-12-08 15:16:23
 * @LastEditTime: 2024-07-29 14:03:16
 * @LastEditors: zjc
-->
<template>
  <div>
    <div class="menu-flex" :style="{paddingBottom:isMargin?'10px':'20px'}">
      <div class="menu-flex__left" v-if="isMenuLeft">
        <slot name="menuLeft_prex"></slot>
        <div v-if="isInput" style="white-space: nowrap"
          :style="{marginBottom:isMargin?'10px':'0px'}">
          <slot name="select"></slot>
          <el-input style="width: 290px" @input="inputVal" v-model="val" clearable
            :placeholder="placeholder" @clear="clearVal">
            <template #prefix>
              <el-icon class="el-input__icon">
                <search />
              </el-icon>
            </template>
          </el-input>
        </div>

        <!-- <el-checkbox-group v-model="checked" v-if="list.length" @change="change">
          <el-checkbox v-for="item in list" :key="item.value" :label="item.value">{{ item.label }}</el-checkbox>
        </el-checkbox-group> -->

        <el-select v-model="checked" clearable :placeholder="isDropTitle ? isDropTitle : '请选择状态'"
          v-if="list.length" style="margin-left: 10px" class="select-box" @change="change">
          <el-option v-for="item in list" :key="item.value" :label="item.label"
            :value="item.value" />
        </el-select>
        <slot name="menuRight_prex"></slot>
        <slot name="selectRight"></slot>

        <el-button type="primary" size="small" @click="searchChange" style="margin-left: 10px"
          :style="{marginBottom:isMargin?'10px':'0px'}">
          <el-icon style="margin-right: 5px">
            <Search />
          </el-icon>
          查询
        </el-button>
        <el-button size="small" @click="refresh" :style="{marginBottom:isMargin?'10px':'0px'}">
          <el-icon style="margin-right: 5px">
            <Refresh />
          </el-icon>
          重置
        </el-button>
        <slot name="menuLeft"></slot>
      </div>
      <div v-else></div>
      <div class="menu-flex__right" v-if="isMenuRight">
        <slot name="menuRight"></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps({
  //是否直接请求接口不通过查询按钮
  directRequest: {
    type: Boolean,
    default: false,
  },
  isInput: {
    //不需回车搜索的隐藏，使用插槽自定义搜索
    type: Boolean,
    default: true,
  },
  isMenuLeft: {
    type: Boolean,
    default: true,
  },
  isMenuRight: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: "请输入",
  },
  modelValue: {
    type: String,
    default: "",
  },
  checkList: {
    type: Array,
    default: () => [],
  },
  isDropTitle: {
    type: String,
    default: "请选择状态",
  },
  isMargin: {
    type: Boolean,
    default: false,
  },
});
const val = ref(props.modelValue || "");
const list = ref(props.checkList || []);
const emit = defineEmits(["refresh", "search", "changeCheckbox"]);
import { setStore } from "@/utils/store";

const checked = ref('');

const change = (val) => {
  emit("changeCheckbox", val);
};
//实时触发
const inputVal = () => {
  if (props.directRequest) {
    emit("update:modelValue", val.value);
  }
};
const clearVal = () => {
  val.value = null;
  // checked.value = null;
  emit("update:modelValue", val.value);
};
const refresh = () => {
  setStore({ name: "pageNum", content: 1 });
  val.value = null;
  checked.value = null;
  emit("update:modelValue", "");
  emit("changeCheckbox", "");

  emit("refresh");
};

const searchChange = (value) => {
  console.log(props.isMargin, '1111')
  emit("update:modelValue", val.value);
  emit("search");
};
const reset = () => {
  // checked.value = null;
  val.value = null;
};

const clear = () => {
  checked.value = null;
  val.value = null;
}
defineExpose({ reset, refresh, clear });
</script>
<style lang="scss" scoped>
.menu-flex {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  &__left {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__right {
    flex: 0 0 0px;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    .el-button + .el-button {
      margin-right: 12px;
    }
  }

  .el-input ::v-deep .el-input__wrapper {
    background-color: #f7f8fa;
    box-shadow: none;
    border-radius: 4px;
  }
}

.menu-flex__right {
  i {
    padding: 0px 8px;
    font-size: 32px;
    color: #9ba6ba;
    cursor: pointer;
  }
}

// 样式穿透修改选择框
.menu-flex :deep(.el-select) {
  width: 120px;
}
.menu-flex .select-box :deep(.el-input) {
  width: 120px;
}
.menu-flex .select-box :deep(.el-input__wrapper) {
  background-color: #f7f8fa;
  box-shadow: none;
}
</style>
