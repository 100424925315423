  <!-- 搜索框 -->
<template>
  <div style="padding: 4px 0 11px 0;">
    <el-row class="display-flex">

      <el-col v-for="item in searchList" :key="item.prop" :lg="item.col || 6" :xl="item.col || 6"
        :md="12" :xs="24" :sm="24">
        <div v-if="item.type ==='input' || item.type ==='deviceInput'">
          <!-- input -->
          <el-form-item :label="item.label" :label-width="item.searchWidth">
            <el-input v-model="queryFilters[item.prop]" :placeholder="item.placeholder"
              @keyup.enter.native="searchChange" :type="item.inputType" v-bind="item"
              :clearable="option.clearable || true">
              <template v-if="item.slot" #append>{{item.slotName}}</template>
            </el-input>
          </el-form-item>
        </div>
        <div v-if="item.type ==='select' && !item.slot">
          <!-- select -->
          <el-form-item :label="item.label" :label-width="item.searchWidth"
            @keyup.enter.native="searchChange">
            <el-select v-model="queryFilters[item.prop]" :placeholder="item.placeholder || '请选择'"
              @change="changeInput" :clearable="option.clearable || true">
              <el-option v-for="i in item.selectList" :key="i.value" :label="i.label"
                :value="i.value" @click.native="clickInput(i)"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="item.slot">
          <slot :name="item.prop"></slot>
        </div>
      </el-col>
      <el-col :lg="col || 6" :xl="col || 6" :md="12" :xs="24" :sm="24">
        <el-form-item label-width="10px">
          <el-button type="primary" size="small" @click="searchChange">
            <el-icon style="margin-right:5px;">
              <Search />
            </el-icon>
            查询
          </el-button>
          <el-button size="small" @click="reset">
            <el-icon style="margin-right:5px;">
              <Refresh />
            </el-icon>
            重置
          </el-button>
          <span v-if="column!=0" @click="open" class="open-style">
            <span>{{state.setSearch?'展开':'收起'}}</span>
            <el-icon style="margin-left:6px">
              <ArrowDownBold v-show="state.setSearch" />
              <ArrowUpBold v-show="!state.setSearch" />
            </el-icon>
          </span>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { COMMON_API } from '@/utils/common'
import { nextTick, onMounted, reactive, toRefs, watch } from 'vue'

/***
   * @param {Object} option 查询配置
   *      clearable 是否显示清除icon
   *      column 配置
   *          -- col  栅格默认6 查询重置按钮的栅格
   *          -- prop 查询字段名
   *          -- label 查询名称
   *          -- searchWidth 查询名称宽度
   *          -- inputType 类型输入框原始类型 text number password 等等
   *          -- type 类型 input 输入框 select下拉选择
   *          -- slot 是否插槽
   *          -- placeholder 占位
   *          -- search 是否是查询条件
   *          -- selectList 下拉框列表
   * @param {Object} filters 查询字段
   * @param {Number} column 默认为0不需要展开收起  需要展开收起需要指定一列显示几个查询条件 显示3写3 加上查询重置按钮的栅格不得大于24需要自己控制好         
   */
const props = defineProps({
  option: {
    type: Object,
    default: () => { }
  },
  filters: {
    type: Object
  },
  col: {
    type: Number,
    default: 6
  },
  column: {
    type: [Number, String],
    default: 0,
  },
})
const emit = defineEmits(['update:filters', 'searchChange', 'select', 'clickInput', 'reset'])

const state = reactive({
  queryFilters: {},// 表单
  searchList: [], // 过滤需要展示的查询
  setSearch: false,
  deepList: [],
})
watch(
  () => state.queryFilters,
  (val) => {
    return emit('update:filters', Object.assign(val))
  },
  {
    deep: true // 深度监听
  }
)
const { queryFilters, searchList } = toRefs(state)
//展开收起
const open = () => {
  state.setSearch = !state.setSearch
  if (state.setSearch) {
    state.searchList = state.deepList.slice(0, props.column || state.deepList.length)
  } else {
    state.searchList = state.deepList
  }
}
const searchChange = () => {
  emit('searchChange')
}
const changeInput = (val) => {
  emit('select', val)
}
const clickInput = (val) => {
  emit('clickInput', val)
}
const reset = () => {
  emit('reset')
}
// 初始化表单
const initForm = () => {
  const queryFilters = {}
  for (const i of state.searchList) {
    if (i.type === 'checkbox' || i.type === 'cascader' || i.type === 'datetimerange') {
      queryFilters[i.prop] = []
    } else if (i.type === 'number') {
      queryFilters[i.prop] = 0
    } else {
      queryFilters[i.prop] = null
    }
  }
  state.queryFilters = queryFilters
}
const initList = () => {
  nextTick(() => {
    state.deepList = COMMON_API.deepClone(props.option) //深拷贝
    state.searchList = state.deepList
  })
}
defineExpose({ initForm, initList })
onMounted(() => {
  nextTick(() => {
    state.deepList = COMMON_API.deepClone(props.option) //深拷贝
    state.searchList = state.deepList
    initForm()
  })

})
</script>
<style lang="scss" scoped>
.inline-block {
  display: inline-block;
}

.open-style {
  margin-left: 10px;
  color: var(--color-primary);
  position: relative;
  top: 2px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
</style>
