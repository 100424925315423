/*
 * @Description: 获取图片包路径
 * @Author: zjc
 * @Date: 2023-06-15 14:02:09
 * @LastEditTime: 2023-08-02 11:50:36
 * @LastEditors: zjc
 */
import { setStore, getStore } from '@/utils/store.js'
let imgFile = ''
let libraryTitle = ''
let logo = ''
const setTheme = (host) => {
  switch (host) {
    case 'www.baidu.com':
      break;
    default:
      imgFile = 'library'
      document.body.setAttribute('class', 'library')
      libraryTitle = '云书馆'
  }
  setStore({ name: 'imgFile', content: imgFile })
}

const getAssetUrl = (image) => {
  const getImgRoute = getStore({ name: 'imgFile' })
  return new URL(`../assets/img/${getImgRoute}/${image}`, import.meta.url).href
}
export {
  setTheme,
  getAssetUrl,
  libraryTitle
}