/*
 * @Description:全局组件
 * @Author: zjc
 * @Date: 2021-12-07 13:46:19
 * @LastEditTime: 2024-08-05 15:05:31
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import vtable from '@/components/CRUD/v-table.vue'
import formMenu from '@/components/CRUD/formMenu.vue'
import formItem from '@/components/CRUD/formItem.vue'
import modalItem from '@/components/CRUD/modalItem.vue'  //只满足没有逻辑的新增、修改
import crossLine from '@/components/CRUD/crossLine.vue'
import pagination from '@/components/pagination/index.vue'
import basicContainer from '@/components/container/index.vue'
import operationTips from '@/components/operationTips/index.vue'
import statisticsTitle from '@/views/statistics/component/statisticsTitle.vue'
import dataOverview from '@/views/statistics/component/dataOverview.vue'
import dataTrend from '@/views/statistics/component/dataTrend.vue'
import statisticsTabs from '@/views/statistics/component/statisticsTabs.vue'
import stateIcon from '@/components/CRUD/stateIcon.vue' // table表格状态栏
import formSearch from '@/components/CRUD/formSearch.vue'
import dictTag from '@/components/CRUD/dictTag.vue'
import Vbreadcrumb from '@/components/CRUD/v-breadcrumb.vue'
import organizationTabs from '@/components/CRUD/organizationTabs.vue'
import noPermission from '@/components/CRUD/noPermission.vue' //无权限
import treeSelect from '@/components/CRUD/tree-select.vue' //下拉选择组织，虚拟列表
import vEmpty from '@/components/vEmpty/index.vue' //h5空页面
export function setupComponent (app) {
  app.component('v-table', vtable)
  app.component('pagination', pagination)
  app.component('basic-container', basicContainer)
  app.component('form-menu', formMenu)
  app.component('form-item', formItem)
  app.component('modal-item', modalItem)
  app.component('operation-tips', operationTips)
  app.component('cross-line', crossLine)
  app.component('statistics-title', statisticsTitle)
  app.component('data-overview', dataOverview)
  app.component('data-trend', dataTrend)
  app.component('statistics-tabs', statisticsTabs)
  app.component('state-icon', stateIcon)
  app.component('form-search', formSearch)
  app.component('dict-tag', dictTag)
  app.component('v-breadcrumb', Vbreadcrumb)
  app.component('organization-tabs', organizationTabs)
  app.component('no-permission', noPermission)
  app.component('tree-select', treeSelect)
  app.component('v-empty', vEmpty)
}
