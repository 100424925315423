/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-08-22 10:08:27
 * @LastEditTime: 2022-08-22 17:18:43
 * @LastEditors: zjc
 */
import { createApp } from 'vue'
const app = createApp({})
import SvgIcon from '@/components/SvgIcon/index.vue'// svg component
// register globally
app.component('svg-icon', SvgIcon)

