<!--
 * @Description: 菜单列表
 * @Author: zjc
 * @Date: 2021-12-07 15:26:42
 * @LastEditTime: 2024-11-14 11:19:56
 * @LastEditors: zjc
-->
<template>
  <div>
    <template v-for="(item) in menus" :key="item.authUrl">
      <el-sub-menu v-if="item.children && item.children.length>0" :index="item.authUrl">
        <template #title>
          <svg-icon :icon-class="item.authIcon" class="menu-svg" />
          <span>{{item.authName}}</span>
        </template>
        <!-- 递归组件 -->
        <nav-menu-item :menus="item.children"></nav-menu-item>
      </el-sub-menu>
      <el-menu-item v-else :index="item.authUrl" @click="setMenu(item.authUrl)">
        <!-- <svg-icon :icon-class="item.icon" style="margin-right: 10px;color: #FFCECE;" /> -->
        <template #title>
          <span>{{item.authName}}</span>
        </template>
      </el-menu-item>
    </template>
  </div>
</template>
<script setup>
import { adminStore } from '@/store/user.js'
import { setStore, getStore } from '@/utils/store'
const useStore = adminStore()
const props = defineProps({
  menus: {
    type: Array,
    default: function () {
      return [];
    },
  },
  collapse: {
    type: Boolean,
    default: false
  }
})
const setMenu = (url) => {
  setStore({ name: 'pageNum', content: 1 })
  useStore.setMenuUrl(url)
  let libraryId_new = getStore({ name: 'libraryLists' }) ? getStore({ name: 'libraryLists' }) : [];
  if (libraryId_new.length > 0) {
    setStore({ name: 'libraryId', content: libraryId_new[0].libraryId })
  }
}
</script>
<style lang="scss" scoped>
.active::after {
  position: absolute;
  content: "";
  width: 172px !important;
  height: 32px !important;
  background: rgba(255, 255, 255, 0.09) !important;
  border-radius: 6px !important;
  top: 8px !important;
  left: 14px !important;
}
</style>