<!--
 * @Description: table表格状态栏
 * @Author: ym
 * @Date: 2023-06-08 09:40:00
 * @LastEditTime: 2023-06-08 10:24:10
 * @LastEditors: ym
-->
<template>
  <div class="state-icon">
    <span class="circle" :style="{'background-color': color? color: getType(type)}"></span>
    <span>{{title}}</span>
  </div>
</template>
<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''//#409eff 蓝色  // #55dd8c绿色 // #d7311e红色  //灰色#585858
  },
  type: {
    type: [String, Number],
    default: 1, //1、#409eff 蓝色。2、#55dd8c绿色。3、#d7311e红色。4、灰色#585858
  }

})

const getType = (type) => {
  let num = Number(type)
  switch (num) {
    case 1:
      return '#409eff';
      break;
    case 2:
      return '#55dd8c';
      break;
    case 3:
      return '#d7311e';
      break;
    default:
      return '#585858';
  }
}
</script>
<style lang="scss" scoped>
.state-icon {
  span {
    vertical-align: middle;
  }
  .circle {
    background-color: #409eff;
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
</style>