<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2022-04-12 11:19:38
 * @LastEditTime: 2024-08-27 14:40:46
 * @LastEditors: 杨明 ym@geeboo.cn
-->
<template>
  <div>
    <div class="main">
      <!-- <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view> -->

       <router-view v-slot="{ Component, route }">
         <keep-alive>
            <component :is="Component" :key="route.path"  v-if="route.meta.isKeepAlive" />
         </keep-alive>
         <component  v-if="!route.meta.isKeepAlive" :is="Component" :key="route.path"/>
      </router-view>

    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { nextTick, ref, watch } from 'vue'
const route = useRoute()
const router = useRouter()

//统计的页面不要动画，会影响部门下拉的固定定位样式
const noAnimation = [
  '/dataStatistics/userStatistics/index', '/dataStatistics/periodStatistics/index', '/dataStatistics/readStatistics/index', '/dataStatistics/consensusStatistics/index', '/dataStatistics/courseStatistics/index', '/dataStatistics/datumStatistics/index'
]
const res = ref(false)
watch(
  () => router.currentRoute.value,
  (newValue) => {
    nextTick(() => {
      res.value = noAnimation.some(item => item !== newValue.path)
    })
  },
  { immediate: true }
)
</script>
<style lang="scss">
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.3s ease;
}

// slide-right
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
.main {
  overflow: hidden;
  padding-top: 84px;
}
.arcsin {
  width: 100%;
  z-index: 998;
}
.router-view {
  height: 100%;
}
.close-main {
  width: 74px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-primary);
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  .el-icon--right {
    margin-left: 4px;
    font-size: 16px;
    color: #fff;
    vertical-align: middle;
  }
}
</style>