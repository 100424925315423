/*
 * @Description: 提示语hook
 * @Author: zjc
 * @Date: 2022-08-22 13:37:10
 * @LastEditTime: 2023-07-07 11:54:44
 * @LastEditors: zjc
 */
import { ElNotification, ElMessage } from 'element-plus'
const useMsg = (obj) => {
  //通知
  const useNotification = (obj) => {
    ElNotification({
      title: obj.title || '提示',
      message: obj.msg,
      duration: 2000,
      type: obj.type,
    })
  }
  const useMessage = (obj) => {
    ElMessage({
      message: obj.msg,
      duration: 2000,
      type: obj.type,
    })
  }
  return {
    useNotification,//通知
    useMessage,//提示消息
  }
}
export default useMsg