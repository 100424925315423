<!--
 * @Description: tab切换
 * @Author: zjc
 * @Date: 2023-04-23 14:29:35
 * @LastEditTime: 2023-05-16 14:06:04
 * @LastEditors: zjc
-->
<template>
  <view class="radio-btn" v-if="organization.orgType!==303">
    <el-radio-group v-model="activeName" size="large" :style="{margin:margin}">
      <el-radio-button v-for="item in orgTypeList" :key="item.name" :label="item.name"
        @change="changeRadio(item)">{{item.label}}</el-radio-button>
    </el-radio-group>
  </view>

</template>
<script setup>
import { reactive, ref, toRefs, computed, onMounted } from 'vue'
import { orgTypeList } from '../enums/index.js'
import { adminStore } from '@/store/user.js'
const userStore = adminStore()
const props = defineProps({
  margin: {
    type: String,
    default: '15px 0px 23px'
  },
  defaultActive: {
    type: String,
    default: '1'
  }
})
const state = reactive({
  organization: computed(() => userStore.organization), // 组织信息
})
const { organization } = toRefs(state)
const emits = defineEmits(['change'])
const activeName = ref(props.defaultActive)
const changeRadio = (val) => {
  emits('change', val)
}
onMounted(() => {
  if (state.organization.orgType === 301) {
    orgTypeList[0].label = '党委总概况'
  } else {
    orgTypeList[0].label = '党总支总概况'
  }
})
</script>
<style lang="scss" scoped>
.radio-btn {
  :deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
    color: $--color-primary;
    box-shadow: none;
    border-left: 1px solid;
    // border: 2px solid;
    background-color: transparent;
    border-color: $--color-primary;
    // font-weight: bold;
    box-shadow: none;
    background: #fff;
  }
  :deep(.el-radio-button__inner:hover) {
    color: $--color-primary;
  }
  :deep(.el-radio-button__inner) {
    color: #6a7484;
  }
}
</style>