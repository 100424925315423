/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-08 10:26:03
 * @LastEditTime: 2024-08-23 11:31:34
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import { staticRoute, asyncRouteMap } from '@/router/index'
import { COMMON_API } from '@/utils/common'
import { defineStore } from 'pinia'
const routeAllPathToCompMap = import.meta.glob(`../views/**/*.vue`)
function hasPermission (roles) {
  let modules = null
  let routerList = {}
  for (const key in routeAllPathToCompMap) {
    modules = key.substring(9, key.length - 4)
    if (modules === roles.authUrl.replace('/', '')) {
      routerList = {
        path: `${roles.authUrl}`,
        name: roles.authName,
        meta: { menuId: roles.id, isKeepAlive: false },
        component: routeAllPathToCompMap[`../views${roles.authUrl}.vue`],
      }
      return routerList
    }
  }
}
export const authStore = defineStore('auth', {
  state: () => {
    return {
      addRouters: [],
      routers: staticRoute,
    }
  },
  getters: {
    getRouters: state => state.routers,
    getAddRouters: state => state.addRouters,
  },
  actions: {
    setPath (routers) {
      this.addRouters = [...routers]
      this.routers = staticRoute.concat(routers)
    },
    GenerateRoutes (permission) {
      const router = []
      const menuList = COMMON_API.treeToList(permission)
      const authList = menuList.filter(item => {
        return item.children.length === 0
      })
      return new Promise(resolve => {
        for (const list of authList) {
          const routerObj = hasPermission(list)
          if (routerObj) {
            router.push(routerObj)
          }
        }
        for (const i of asyncRouteMap) {
          i.children = [...router]
        }
        this.setPath(asyncRouteMap)
        resolve(asyncRouteMap)
      })
    }
  },
})