<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 17:28:27
 * @LastEditTime: 2023-06-30 16:42:05
 * @LastEditors: zjc
-->
<template>
  <div v-if="isOnlineSvg" :style="{ '--svg-icon-url': `url(${iconClass})` }"
    class="svg-icon svg-icon-online" :class="svgClass" />
  <svg v-else class="svg-icon" :class="svgClass" aria-hidden="true">
    <use :xlink:href="`#icon-${iconClass}`" />
  </svg>
</template>

<script  setup>
import { computed } from 'vue'

const props = defineProps({
  // SVG 图标名称或在线URL
  iconClass: {
    type: String,
    required: true
  },
  // 图标类名
  svgClass: {
    type: String,
    default: ''
  }
})

const isOnlineSvg = computed(() => /^(https?:)/.test(props.iconClass))
</script>

<style scoped lang="scss">
.svg-icon {
  width: 1em;
  height: 1em;
  // fill: currentColor;
  // stroke: currentColor;
  overflow: hidden;
}

.svg-icon-online {
  background-color: currentColor;
  mask-image: var(--svg-icon-url);
  -webkit-mask-image: var(--svg-icon-url);
  mask-size: cover;
  -webkit-mask-size: cover;
  display: inline-block;
}
</style>