<template>
  <div class="breadcrumb">
    <el-breadcrumb :separator="props.separator">
      <el-breadcrumb-item :to="item.path?item.path:''" v-for="(item,index) in props.options"
        :key="index">{{ item.label }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script setup>
const props = defineProps({
  //面包屑数据
  options: {
    type: Array,
    default: () => []
  },
  //分隔符
  separator: {
    type: String,
    default: '/'
  }
})
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e8eb;
  margin-bottom: 20px;
  :deep(.el-breadcrumb__inner) {
    font-weight: 400;
    color: #9ba6ba;
  }
  :deep(.el-breadcrumb) {
    line-height: 15px;
  }
}
</style>