/*
 * @Author: 杨明 ym@geeboo.cn
 * @Date: 2024-08-21 11:13:01
 * @LastEditors: 杨明 ym@geeboo.cn
 * @LastEditTime: 2024-08-23 11:49:01
 * @FilePath: \zglibrary-pc-ui\src\hooks\useRouteCache.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { ref, nextTick } from 'vue'
 
const caches = ref([])
 
export default function useRouteCache () {
  // 添加缓存的路由组件
  function addCache (componentName) {
    if (Array.isArray(componentName)) {
      componentName.forEach(addCache)
      return
    }
    
    if (!componentName || caches.value.includes(componentName)) return
 
    caches.value.push(componentName)
  }
 
  // 移除缓存的路由组件
  function removeCache (componentName) {
    const index = caches.value.indexOf(componentName)
    if (index > -1) {
      return caches.value.splice(index, 1)
    }
  }
  // 清空路由
  function clearCache () {
    caches.value = [];
  }
  
  // 移除缓存的路由组件的实例
  async function removeCacheEntry (componentName) {    
    if (removeCache(componentName)) {
      await nextTick()
      addCache(componentName)
    }
  }
  return {
    caches,
    addCache,
    removeCache,
    clearCache,
    removeCacheEntry
  }
}
