/*
 * @Description: 图书馆设置
 * @Author: zjc
 * @Date: 2022-04-28 14:51:32
 * @LastEditTime: 2023-07-17 09:25:18
 * @LastEditors: ym
 */
import request from '@/utils/request'
const libraryInfo = (params = {}) => request('get', '/lms/libInfo', params) //图书馆信息
const openPipeFitting = () => request('get', '/lms/lib-config/inner/openPipeFitting') //开通馆配
const getPipeFitting = () => request('get', '/lms/lib-config/inner/getIsOpenPipeFitting') //获取馆配是否开通状态


const advCreate = (params) => request('post', '/library/adv/create', params) // 创建广告
const advUpdate = (params) => request('post', '/library/adv/update', params) // 修改广告
const advDelete = (params) => request('post', '/library/adv/delete', params) // 删除广告
const advList = (params) => request('post', '/library/adv/query', params) // 广告列表

const getModuleCreate = (params) => request('post', '/library/layout-module/create', params, { requestType: 'JSON' }) //新增模块
const getModuleUpdate = (params) => request('post', '/library/layout-module/update', params, { requestType: 'JSON' }) //修改模块
const getModuleUpdateSort = (params) => request('post', '/library/layout-module/update-sort', params, { requestType: 'JSON' }) //修改排序

const getLayoutModuleDetail = (params) => request('post', '/library/layout-module/get-layout-module-detail', params) //首页右侧列表内容

const getLayoutModuleList = (params) => request('post', '/library/layout-module/get-app-layout-module-list', params) //首页左侧列表内容
const getAppLayoutModuleDetail = (params) => request('post', '/library/layout-module/get-app-layout-module-detail', params) //首页左侧列表明细内容



// const getAddUpdate = (params) => request('post', '/org/role/update', params, { requestType: 'JSON' })
const getModuleList = (params) => request('post', '/library/layout-module/get-layout-module-list', params) //模块列表
const getModuleDelete = (params) => request('post', '/library/layout-module/delete', params) //删除模块

export const LIBRARY_API = {
  libraryInfo,
  openPipeFitting,
  getPipeFitting,
  advCreate,
  advUpdate,
  advDelete,
  advList,
  getModuleCreate,
  getModuleList,
  getModuleDelete,
  getModuleUpdate,
  getModuleUpdateSort,
  getLayoutModuleDetail,
  getLayoutModuleList,
  getAppLayoutModuleDetail
}