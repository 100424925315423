<!--
 * @Description: 新增、修改组件
 * @Author: zjc
 * @Date: 2021-12-10 13:44:10
 * @LastEditTime: 2023-09-04 09:07:54
 * @LastEditors: ym
-->
<template>
  <el-dialog :title="dialogData.title" :width="option.dialogWdith" @close="beforeClose"
    append-to-body :close-on-click-modal="false" :top="option.top || '10vh'" v-model="visible">
    <el-form ref="submitForm" class="modal" :model="tableForm" :size="option.size"
      :label-width="option.labelWidth" :rules="tableFormRules">
      <el-row :gutter="option.gutter || 0">
        <template v-for="(item,index) in formOption" :key="`${index}`">
          <el-col :span="item.span || 24">
            <!-- input -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='input'">
              <el-input @input="handleInput" v-model="tableForm[item.prop]"
                :placeholder="item.placeholder || '请输入'" :type="item.inputType" :rows="item.rows"
                :clearable="option.clearable" :disabled="item.isDisabled">
                <template v-if="item.append" #append>{{item.slotName}}</template>
              </el-input>
              <span class="show-pwd" v-if="item.isPassWord && tableForm[item.prop]"
                @click="item.inputType === 'password'? item.inputType = 'text' : item.inputType = 'password'">
                <svg-icon :icon-class="item.inputType === 'password' ? 'eye' : 'eye-open'" />
              </span>
            </el-form-item>

            <!-- select -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='select'" class="select_form_item">
              <el-select @change="selectChange" v-model="tableForm[item.prop]"
                :filterable="item.filterable" :placeholder="item.placeholder || '请选择'"
                style="width: 100%;" :clearable="option.clearable">
                <el-option v-for="i in item.selectList" :key="i.value" :label="i.label"
                  :value="i.value"></el-option>
              </el-select>
            </el-form-item>

            <!-- radio -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='radio'">
              <el-radio-group v-model="tableForm[item.prop]" @change="radioChange">
                <el-radio v-for="(i,index) in item.radioList" :key="index" :label="i.value"
                  :border="item.border">
                  {{i.label}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- checkBox -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='checkbox'">
              <el-checkbox-group v-model="tableForm[item.prop]" @change="checkboxChange">
                <el-checkbox v-for="(i,index) in item.checkboxList" :label="i.value" :key="index"
                  :border="item.border">
                  {{i.label}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <!-- date -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='date'">
              <el-date-picker @change="dateChange" v-model="tableForm[item.prop]"
                :type="item.inputType" :placeholder="item.placeholder || '请选择'"
                :clearable="option.clearable" :value-format="item.valueFormat"
                :format="item.format"></el-date-picker>
            </el-form-item>

            <!-- time -->
            <el-form-item :label="item.label" :prop="item.prop" :label-width="item.width"
              v-if="item.type ==='time'">
              <el-time-picker @change="timeChange" :arrow-control="item.arrow"
                v-model="tableForm[item.prop]" :size="option.size" value-format="HH:mm:ss"
                :picker-options="item.selectableRange" :placeholder="item.placeholder || '请选择时间'">
              </el-time-picker>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="beforeClose">取消</el-button>
        <el-button type="primary" @click="rowUpdate" :loading="loading" v-if="dialogData.id">确定
        </el-button>
        <el-button type="primary" @click="rowSave" :loading="loading" v-if="!dialogData.id">确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { computed, onMounted, reactive, watch, ref, toRefs } from 'vue'
import { COMMON_API } from '@/utils/common'
/**
* @param {Object} dialogData 弹窗对象 有id title showDialog
* @param {Object} formData 表单新增、修改数据 
* @param {Object} option 新增、修改配置
*          -- dialogWdith 弹窗宽度
*          -- top 弹窗距离顶部位置
*          -- labelWidth 表单文字宽度
*          -- gutter 栅格间隔
*          -- size 尺寸
*          -- clearable 是否显示清除按钮
*       column  表单配置
*          prop 字段名
*          label 名称
*          width 名称宽度
*          placeholder 占位
*          type 标签type input select 等等
*          inputType 输入框类型
*          rules  表单校验
*/
const props = defineProps({
  dialogData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  formData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  option: {
    type: Object,
    default: () => {
      return {}
    }
  },
})
const state = reactive({
  tableForm: {},
  visible: false,
  tableFormRules: {},
  type: '',
  loading: false,
})
const { tableForm, visible, tableFormRules, type, loading } = toRefs(state)
const submitForm = ref(null)
const emit = defineEmits(['update:form', 'row-save', 'row-update', 'radio', 'select', 'date', 'time', 'checkbox', 'input'])
watch(() => state.tableForm, (newVal) => {
  return emit('update:form', Object.assign(newVal))
}, {
  deep: true,
})
const formOption = computed(() => {
  if (props.dialogData.id) {
    return props.option.column.filter((item) => !item.hasOwnProperty('modalHide'))
  } else {
    return props.option.column.filter((item) => !item.hasOwnProperty('edit'))
  }
})

const init = () => {
  let tableForm = {}
  for (let i of formOption.value) {
    if (i.type === 'checkbox' || i.type === 'cascader' || i.type === 'datetimerange') {
      tableForm[i.prop] = []
    } else if (i.type === 'number') {
      tableForm[i.prop] = 0
    } else if (i.isPassWord) {
      i.inputType = 'password'
    } else {
      tableForm[i.prop] = ''
    }
    if (i.rules) {
      state.tableFormRules[i.prop] = i.rules
    }
  }
  state.tableForm = tableForm
  if (!COMMON_API.validatenull(props.dialogData.id)) {
    state.tableForm = props.formData
  }
}
// 新增
const rowSave = () => {
  submitForm.value.validate(valid => {
    if (valid) {
      state.loading = true
      emit('row-save', Object.assign({}, state.tableForm))
    }
  })
}
// 修改 
const rowUpdate = () => {
  submitForm.value.validate(valid => {
    if (valid) {
      state.loading = true
      emit('row-update', Object.assign({}, state.tableForm))
    }
  })
}
const beforeClose = () => {
  state.visible = false
  props.dialogData.showDialog = false
  submitForm.value.resetFields()
}
// radio
const radioChange = (val) => {
  emit('radio', val)
}
// select
const selectChange = (val) => {
  $emit('select', val)
}
// date
const dateChange = (val) => {
  emit('date', val)
}
// time
const timeChange = (val) => {
  emit('time', val)
}
// checkBox
const checkboxChange = (val) => {
  emit('checkbox', val)
}
// input
const handleInput = (val) => {
  emit('input', val)
}

onMounted(() => {
  state.visible = props.dialogData.showDialog
  init()
})
defineExpose({ loading })
</script>
<style lang="scss" scoped>
.modal {
  el-date-editor--month > .el-input__inner {
    border: 1px solid #dcdfe6 !important;
  }
  .el-date-editor.el-input {
    width: 100%;
  }
}
.show-pwd {
  position: absolute;
  right: 30px;
  top: 3px;
  font-size: 16px;
  color: #ddd;
  cursor: pointer;
  user-select: none;
}
</style>