/*
 * @Description:过滤
 * @Author: zjc
 * @Date: 2021-12-07 11:39:53
 * @LastEditTime: 2024-07-26 10:27:43
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import moment from 'dayjs'
import { COMMON_API } from '@/utils/common'
import { adminStore } from '@/store/user.js'
import duration from 'dayjs/plugin/duration'
moment.extend(duration)
/**
 * 日期格式化
 * @param {value}
 * @param {foramt}    
 */
const formatDate = (value, format = "YYYY-MM-DD HH:mm:ss") => {
  return value ? moment(value).format(format) : moment().format(format)
}
/**
 * 数字添加千分符
 * @param {value}  
 */
const formatCount = (value = 0) => {
  return Number(value).toLocaleString('zh', { style: 'decimal' })
}
/**
 * 文件大小转换
 * @param {*} size
 * @return {*} 
 */
const transformByte = (size) => {
  if (!size) {
    return '-';
  }
  var num = 1024.0; // byte
  if (size < num) {
    return size + 'B';
  }
  if (size < Math.pow(num, 2)) {
    return (size / num).toFixed(2) + 'KB';
  } // kb
  if (size < Math.pow(num, 3)) {
    return (size / Math.pow(num, 2)).toFixed(2) + 'MB';
  } // M
  if (size < Math.pow(num, 4)) {
    return (size / Math.pow(num, 3)).toFixed(2) + 'G';
  } // G
  return (size / Math.pow(num, 4)).toFixed(2) + 'T'; // T
}
// 设置绝对值
const getNum = (num) => {
  return num < 0 ? Number(Math.abs(num)).toFixed(2) : Number(num).toFixed(2)
}
//根据type获取时间 8 -- 7天 31 -- 30天 61 -- 60天 91 -- 90天 1 -- 1年 all -- 全部  10 -- 本月初到今天  11--今年第一天到今天  12-- 上个月1号到上个月底
//path 页面标识
const getDate = (type, typeName = null) => {
  const userStore = adminStore()
  const createTime = userStore.libraryInfo.createTime
  let end = null
  if (typeName) end = moment().format('YYYY-MM-DD')
  else end = moment().add(-1, 'day').format('YYYY-MM-DD')
  let start = null
  if (['8', '31', '61', '91'].includes(type)) {
    start = moment().add(-Number(type), 'day').format('YYYY-MM-DD')
  } else if ((['7', '15', '30', '90'].includes(type))) {
    start = moment().add(-Number(type) + 1, 'day').format('YYYY-MM-DD')
    if (!typeName) start = moment().add(-Number(type), 'day').format('YYYY-MM-DD')
  } else if ((['2'].includes(type))) {
    start = null
    end = null
  } else if (type === '1') {
    start = moment().add(-1, 'year').format('YYYY-MM-DD')
  } else if (type === '10') { //月初到今天
    start = moment().startOf('month').format("YYYY-MM-DD")
    end = moment().format('YYYY-MM-DD')
  } else if (type === '11') { //今年第一天到今天
    start = moment().startOf('year').format("YYYY-MM-DD")
    end = moment().format('YYYY-MM-DD')
  } else if (type === '12') { //上个月1号到上个月底
    start = moment().add(-1, 'month').format('YYYY-MM-01')
    end = moment().add(-1, 'month').endOf('month').format("YYYY-MM-DD")
  } else {
    start = null
  }
  return { end, start }
}
const getDay = (type) => {
  let endTime = null
  let startTime = null
  let end = null
  if ((['7', '30', '90'].includes(type))) {
    startTime = moment().add(-Number(type) * 2, 'day').format('YYYY-MM-DD')
    end = moment().add(-Number(type), 'day').format('YYYY-MM-DD')
    endTime = moment(end).add(-1, 'day').format('YYYY-MM-DD')
  } else if (type === '1') {
    startTime = moment().add(-2, 'year').format('YYYY-MM-DD')
    end = moment().add(-1, 'year').format('YYYY-MM-DD')
    endTime = moment(end).add(-1, 'day').format('YYYY-MM-DD')
  }
  return { startTime, endTime }
}
// 获取 1本周、-1上周、2本月、-2上月、3本季度、-3上季度的开始结束日期
const getAppointDate = (type) => {
	
	 let end = new Date();
	 let start = new Date();
	 if(type == 1) {
		 // 本周
		 start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
	 } else if (type == -1) {
		 // 上周
		 start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
		 end.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
		
	 } else if (type == 2) {
		 // 本月
		  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
	 } else if (type == -2) {
		 // 上月
		  start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
		 end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
	 } else if (type == 3) {
		 // 本季度
		 start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
	 } else if (type == -3) {
		 // 上季度
		  start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
		  end.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
	 }
	return {start: formatDateNew(start),end: formatDateNew(end)}
}
//格式化日期：yyyy-MM-dd
const formatDateNew = (date) => {
    var myyear = date.getFullYear();
    var mymonth = date.getMonth() + 1;
    var myweekday = date.getDate();
    if (mymonth < 10) {
        mymonth = "0" + mymonth;
    }
    if (myweekday < 10) {
        myweekday = "0" + myweekday;
    }
    return (myyear + "-" + mymonth + "-" + myweekday);
}
//获得某月的天数
const getMonthDays = (myMonth) =>{
	var now = new Date(); //当前日期
	var nowYear = now.getYear(); //当前年
	nowYear += (nowYear < 2000) ? 1900 : 0; //
    var monthStartDate = new Date(nowYear, myMonth, 1);
    var monthEndDate = new Date(nowYear, myMonth + 1, 1);
    var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
    return days;
}
//获得本季度的开始月份
const getQuarterStartMonth = () => {
	var now = new Date(); //当前日期
	var nowMonth = now.getMonth(); //当前月
    var quarterStartMonth = 0;
    if (nowMonth < 3) {
        quarterStartMonth = 0;
    }
    if (2 < nowMonth && nowMonth < 6) {
        quarterStartMonth = 3;
    }
    if (5 < nowMonth && nowMonth < 9) {
        quarterStartMonth = 6;
    }
    if (nowMonth > 8) {
        quarterStartMonth = 9;
    }
    return quarterStartMonth;
}
const formatSecond = (value, type = 'seconds') => {
  let obj = {}
  let hh = Math.floor(+value / 60 / 60) // 小时
  let mNext = Math.floor((+value / 60) % 60) //向下取整分钟
  let ss = +value % 60 //秒
  let minute = Math.ceil(+value / 60) //总分钟
  obj = {
    h: hh, //小时
    mu: mNext, //向下取整分钟
    se: parseInt(ss), //秒
  }
  let timeStr = null
  if (type === 'seconds') {
    if (obj.h > 0) timeStr = obj.h + '时' + obj.mu + '分' + obj.se + '秒'
    else if (obj.mu > 0) timeStr = obj.mu + '分' + obj.se + '秒'
    else if (obj.se > 0) timeStr = obj.se + '秒'
    else timeStr = 0
  } else if (type === 'hours') {
    timeStr = minute / 60
  } else {
    timeStr = obj
  }
  // console.log(obj, '2')
  return timeStr
}
const formatSecondNew = (value, type = 'seconds') => {
  let obj = {}
  let hh = Math.floor(+value / 60 / 60) // 小时
  let mNext = Math.floor((+value / 60) % 60) //向下取整分钟
  let ss = +value % 60 //秒
  let minute = Math.ceil(+value / 60) //总分钟
  obj = {
    h: hh, //小时
    mu: mNext, //向下取整分钟
    se: parseInt(ss), //秒
  }
  let timeStr = null
  if (type === 'seconds') {
    let hou = obj.h < 10 ? '0' + obj.h : obj.h
    let min = obj.mu < 10 ? '0' + obj.mu : obj.mu;
    let sec = obj.se < 10 ? '0' + obj.se : obj.se;
    if (obj.h > 0) timeStr = hou + ':' + min + ':' + sec
    else if (obj.mu > 0) timeStr = '00:' + min + ':' + sec
    else if (obj.se > 0) timeStr = '00:00:' + sec
    else timeStr = 0
  } else if (type === 'hours') {
    timeStr = minute / 60
  } else {
    timeStr = obj
  }
  // console.log(obj, '2')
  return timeStr
}
const formatYesterday = (type = 'YYYY年MM月DD日 23:59:59') => {
  return moment().add(-1, 'day').format(type)
}

// 0小时9分钟
const formatHourAndMinute = (second) => {
  const getHour = (second) => {
    if (!second) return '0小时';
    if (second / 60 / 60 < 1) {
      return '0小时';
    }
    let num = Number.parseInt(second / 60 / 60);
    return (num + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '小时';
  }

  const getMinute = (second) => {
    if (!second) return '0分钟';
    return (Number.parseInt(second / 60) % 60) + '分钟';
  }
  return getHour(second) + getMinute(second);
}

export {
  formatDate,
  formatCount,
  transformByte,
  getNum,
  getDate,
  getDay,
  formatSecond,
  formatSecondNew,
  formatYesterday,
  formatHourAndMinute,
  getAppointDate,
  getMonthDays,
  getQuarterStartMonth,
  formatDateNew
}