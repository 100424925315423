/*
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 11:50:37
 * @LastEditTime: 2023-10-26 09:07:42
 * @LastEditors: xlm
 */

import { COMMON_API } from '@/utils/common'
/**
 * 获取本地存储
 * @param {*} params 
 * @returns 
 */
export const getStore = (params = {}) => {
  let { name } = params;
  let obj = {}, content;
  obj = window.sessionStorage.getItem(name);
  if (COMMON_API.validatenull(obj)) obj = window.localStorage.getItem(name);
  if (COMMON_API.validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch {
    return obj;
  }
  if (obj.dataType == 'string') {
    content = obj.content;
  } else if (obj.dataType == 'number') {
    content = Number(obj.content);
  } else if (obj.dataType == 'boolean') {
    content = eval(obj.content);
  } else if (obj.dataType == 'object') {
    content = obj.content;
  }

  return content;
}
/**
 * 本地缓存
 * @param {*} params 
 */
export const setStore = (params = {}) => {
  let { name, content, type, } = params;
  let obj = {
    dataType: typeof (content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  }
  // if (type) window.sessionStorage.setItem(name, JSON.stringify(obj));
  // else window.localStorage.setItem(name, JSON.stringify(obj));
  window.sessionStorage.setItem(name, JSON.stringify(obj))
}
/**
 * 删除单个存储
 * @param {*} params 
 */
export const removeStore = (params = {}) => {
  let { name, type } = params;
  // if (type) {
  //   window.sessionStorage.removeItem(name);
  // } else {
  //   window.localStorage.removeItem(name);
  // }
  window.sessionStorage.removeItem(name);
}
/**
 * 全部清除存储
 */
export const clearStore = () => {
  window.sessionStorage.clear();
  window.localStorage.clear()
}