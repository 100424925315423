/*
 * @Description: 
 * @Author: zjc
 * @Date: 2023-07-10 10:09:01
 * @LastEditTime: 2024-12-03 14:22:14
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import request from '@/utils/request'

import { orgData } from './org-data'

const memberList = (params) => request('get', '/org/member/page', params, { loading: true }) //获取成员列表
const memberListV2 = (params) => request('get', '/org/member/v2/page', params, { loading: true }) //获取成员列表带权限
const memberList2 = (params) => request('get', '/org/member/v2/page', params, { loading: false }) //获取成员列表带权限
const memberList1 = (params) => request('get', '/org/member/page', params, { loading: false }) //获取成员列表
const updateMember = (params) => request('post', '/org/member/update', params, { requestType: 'JSON' }) //修改成员
const addMember = (params) => request('post', '/org/member/add', params, { requestType: 'JSON' }) //新增成员
const memberInfo = (memberId) => request('get', `/org/member/info/${memberId}`) //获取成员信息
const statusChange = (params) => request('post', '/org/member/batch-status-change', params) //停用、恢复成员
const deptTree = (params) => request('get', '/org/unit/tree', params, { loading: true }) //部门列表树
const deptTree1 = (params) => request('get', '/org/unit/tree', params) //部门列表树（筛选）
// const deptTree = (params) => { return Promise.resolve(orgData) } //部门列表树
// const deptTree1 = (params) => { return Promise.resolve(orgData) } //部门列表树
const deptList = (params) => request('get', '/org/unit/page', params) //获取部门列表
const deptInfo = (unitId) => request('get', `/org/unit/info/${unitId}`) //获取部门信息
const delBatchDeptMember = (params) => request('post', '/org/unit/members-remove', params) //移除部门成员列表
const delDeptMember = (params) => request('post', '/org/unit/member-remove', params) //移除部门成员列表
const delDept = (params) => request('post', '/org/unit/delete', params) //移除部门列表
const addDept = (params) => request('post', '/org/unit/add', params) //新增部门
const batchNameEdit = (params) => request('post', '/org/member/batch-name-reset', params) // 重置昵称
const batchAvatarEdit = (params) => request('post', '/org/member/batch-avatar-reset', params) // 重置头像

const updateDept = (params) => request('post', '/org/unit/update', params) //修改部门
const deptMember = (params) => request('get', `/org/unit/member/${params.deptId}`, { keyWord: params.keyWord }, { loading: true }) //获取部门底下的部门成员
const deptMemberAdd = (params) => request('post', `/org/unit/members-add`, params) //分配部门
const orgList = () => request('get', '/org/org/user-orgs') //获取用户可用租户
const getOrg = () => request('get', '/org/org') //获取当前用户机构
const deptTreeAll = (params) => request('get', '/org/unit/tree-all', params) //通用弹窗部门列表树
const deptTreeAllLoad = (params) => request('get', '/org/unit/tree-all', params, { loading: true }) //通用弹窗部门列表树-加载效果
const deptMemberAll = (params) => request('get', `/org/unit/member-all/${params.deptId}`) //获取通用弹窗部门底下的部门成员
const getRole = () => request('get', '/org/role') //获取当前用户的角色列表
const addDeptAll = (params) => request('post', '/org/member/members-units-add', params) //批量加入部门
const delDeptAll = (params) => request('post', '/org/member/batch-unit-clear', params) //批量加入部门

const importDeptList = (params) => request('post', '/org/unitImportRecord/oms/page', params) //导入记录
const deptExport = (params) => request('post', '/org/unitImportRecord/oms/detail/export', params, { responseType: 'blob' }) //下载导入记录

const synchronousMember = (params) => request('post', '/org//third/iport/sync_member', params) //同步人员
const getSuccessTime = (params) => request('post', '/org/third/iport/success_time', params) //获取成功时间

const deptMember1 = (params) => request('get', `/org/unit/member/page`,params, { loading: true }) //获取部门底下的部门成员（分页）



export const ORG_API = {
  memberList,
  memberList1,
  memberList2,
  memberListV2,
  memberInfo,
  updateMember,
  addMember,
  statusChange,
  deptTree,
  deptList,
  deptInfo,
  delDeptMember,
  delBatchDeptMember,
  delDept,
  addDept,
  updateDept,
  deptMember,
  deptMemberAdd,
  orgList,
  getOrg,
  deptTreeAll,
  importDeptList,
  deptExport,
  deptTreeAllLoad,
  deptMemberAll,
  getRole,
  addDeptAll,
  delDeptAll,
  deptTree1,
  batchNameEdit,
  batchAvatarEdit,
  synchronousMember,
  getSuccessTime,
  deptMember1
}