/*
 * @Description:
 * @Author: zjc
 * @Date: 2021-12-07 11:47:40
 * @LastEditTime: 2024-08-21 16:34:53
 * @LastEditors: zjc
 */
// 配置编译环境和线上环境之间的切换
import { adminStore } from '@/store/user.js'
let title = '党建云书馆后台'
const env = import.meta.env
let LdpUrl
let url = window.location.host

if (url.indexOf('localhost') > -1 || url.indexOf('test') > -1 || url.indexOf('172.') > -1) {
  LdpUrl = 'http://ldpshop-test.elibr.cn'
} else {
  LdpUrl = 'http://ldpshop.elibr.cn'
}

export {
  title,
  env,
  LdpUrl,
}
