/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-08-26 16:43:59
 * @LastEditTime: 2023-08-16 16:05:55
 * @LastEditors: zjc
 */
import { reactive } from 'vue'
import { getStore } from '@/utils/store'
const usePage = (val) => {
  let pageNum = getStore({ name: 'pageNum' })
  //通知
  const pageData = reactive({
    current: pageNum ? pageNum : 1,
    size: 10,
  })

  const defaultPage = () => {
    pageData.current = 1
    pageData.size = 10
  }
  return {
    pageData,//通知
    defaultPage,//重置分页
  }
}
export default usePage