/*
 * @Description:接口请求
 * @Author: zjc
 * @Date: 2021-12-07 11:49:57
 * @LastEditTime: 2024-12-12 17:21:12
 * @LastEditors: 杨明 ym@geeboo.cn
 */
import axios from 'axios'
import { ElNotification } from 'element-plus'
import { getStore } from '@/utils/store'
import { commonStore } from '@/store/common.js'
import { jumpLogin, whiteUrl } from './request'
import { isPc } from '@/validate/validate.js';
import { showToast } from 'vant';
import { adminStore } from '@/store/user.js'
import router from "@/router";

// 适应移动端项目, 弹出框移动端化

const toNotification = (options) => {
  if (isPc()) {
    ElNotification(options)
  } else {
    showToast(options.message);
  }
}

const service = axios.create({
  baseURL: '/api',
  timeout: 300000,// request timeout 

});
service.interceptors.request.use(config => {
  const store = commonStore()
  if (config.loading) {
    store.SET_TABLE_LOADING(true)
  }

  if (config.headers['Tenant-Id'] === undefined) {
    config.headers['Tenant-Id'] = getStore({ name: 'tenantId' }) ? getStore({ name: 'tenantId' }) : 0
  }
  if (config.headers['Authorization'] === undefined) {
    const token = getStore({ name: 'userToken' })
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
  }

  if (config.url === '/auth/oauth2/token') config.headers['Authorization'] = 'Basic ' + 'b3JnOm9yZw=='

  return config
}, error => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(response => {
  const store = commonStore()
  const { code, msg } = response.data
  if ((response.config.url === '/api/auth/oauth2/token' && response.status === 200) || (response.config.url === '/api/auth/public/switchTenant' && response.status === 200)) {
    if (Reflect.has(response.data, 'code')) {
      toNotification({ type: 'error', title: '错误', message: msg || '错误' })
    }
    return Promise.resolve(response.data)
  }
  if (response.data instanceof Blob) return Promise.resolve(response.data)
  if (code === 0) {
    if (response.config.loading) store.SET_TABLE_LOADING(false)
    return Promise.resolve(response.data)
  } else {
    if (response.config.showToast) {
      toNotification({ type: 'error', title: '错误', message: msg || '错误' })
    } else {
      toNotification({ type: 'error', title: '错误', message: msg || '错误' })
    }
    if (response.config.loading) store.SET_TABLE_LOADING(false)
    return Promise.reject(response.data)
  }
}, error => {
  const store = commonStore()
  // 捕获服务器返回的状态码
  if (error && error.response) {
    const { data, config, status } = error.response

    // console.log("window", window.location.hash);
    // 这里判断路由, 检测发现/#/mobile/, 则为移动端页面的路由, 移动端页面这里不需要跳转去登录后台
    let isMobilePage = window.location.hash.startsWith("#/mobile/");
     console.log(window.location.hash, 'window.location.hash')
    if (((status === 424 && !whiteUrl.includes(config.url)) || status === 403) && !isMobilePage) {
      if(config.url != '/api/org/checkGwToken') {
        jumpLogin(error)
      }
    } else {
      if (![`/api/lms/resource`].includes(config.url)) toNotification({ type: 'error', title: '错误', message: data.msg || data.error })
    }
    // let isMobilePage1 = window.location.hash.startsWith("#/mobile/leaderview");

    let isMobilePage1 = window.location.hash.startsWith("#/mobile/");
    // if(((status === 424 && !whiteUrl.includes(config.url)) || status === 403) && isMobilePage1) { //移动端页面(领导视图需要跳转h5登录页)
    //   console.log('领导视图需要登录', '登录')
    //   const userStore = adminStore()
    //   userStore.h5LogOut()
    //   router.push({path: '/mobile/leaderview/login'})
    // }
  }
  // 捕获设置的超时时间
  if (error.message.includes('timeout')) toNotification({ type: 'error', title: '错误', message: '网络超时', })
  if (error.message.includes('Network')) toNotification({ type: 'error', title: '错误', message: window.navigator.onLine ? '服务端异常！' : '您断网了！', })
  store.SET_TABLE_LOADING(false)
  return Promise.reject(error.response)
})
export default service