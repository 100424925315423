<!--
 * @Description: 选择表格需要展示的字段
 * @Author: zjc
 * @Date: 2021-12-15 14:19:27
 * @LastEditTime: 2022-10-13 17:11:04
 * @LastEditors: zjc
-->
<template>
  <div>
    <div class="cheaked-header">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
        @change="handleCheckAllChange">
        全选</el-checkbox>
    </div>
    <div class="checkbox-prop">
      <el-checkbox-group v-model="checkedTable" @change="handleCheckedTableChange">
        <template v-for="item in tableList" :key="item.prop">
          <el-checkbox :label="item.prop">
            {{item.label}}</el-checkbox>
        </template>
      </el-checkbox-group>
    </div>
  </div>
</template>
<script setup>
import { computed, reactive, toRefs } from 'vue';
import useMsg from '@/hooks/useMsg.js'
const { useNotification } = useMsg()
const props = defineProps({
  tablePropList: {
    type: Array
  }
})
const state = reactive({
  checkAll: true, // 默认全部选中
  checkedTable: [],//选中的值
  visible: false, // 是否需要展示dialog
  checkAllList: [],//保存全选数据
  isIndeterminate: true,
  checkLen: 0
})
const { isIndeterminate, checkAll, checkedTable } = toRefs(state)
const tableList = computed(() => props.tablePropList.filter((item) => !item.hasOwnProperty('hide')))

//显示dialog触发
const init = () => {
  state.checkedTable = []
  state.checkAllList = []
  state.visible = true
  for (const i of tableList.value) {
    state.checkAllList.push(i.prop)
    if (i.tableProp) state.checkedTable.push(i.prop)
  }
  state.checkAll = state.checkedTable.length === tableList.value.length;
  if (state.checkAll) state.isIndeterminate = false;
}
//全选按钮
const handleCheckAllChange = (val) => {
  if (!val) {
    state.checkAll = true
    return useNotification({ msg: '至少要选中一个列', type: 'error' })
  }
  state.checkedTable = val ? state.checkAllList : []
  state.isIndeterminate = false;
  confirmShow()
}
//单个选中
const handleCheckedTableChange = (value) => {
  state.checkLen = value.length
  let checkedCount = value.length;
  state.checkAll = checkedCount === state.checkAllList.length;
  state.isIndeterminate = checkedCount > 0 && checkedCount < tableList.value.length;
  confirmShow()
}
//确定
const confirmShow = () => {
  for (let i = 0; i < tableList.value.length; i++) {
    if (state.checkedTable.includes(tableList.value[i].prop)) {
      tableList.value[i].tableProp = true
    } else {
      tableList.value[i].tableProp = false
    }
  }
  emit('changeProp', tableList.value)
}
const emit = defineEmits(['changeProp'])
defineExpose({ init })
</script>
<style>
.el-dropdown-menu {
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.checkbox-prop {
  max-height: 240px;
  overflow: auto;
  padding: 10px 0px 10px 10px;
}
.checkbox-prop .el-checkbox {
  min-width: 90px;
  padding: 7px 0;
  display: block;
}
.cheaked-header {
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
}
</style>