/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-09-08 11:32:05
 * @LastEditTime: 2024-12-04 15:23:14
 * @LastEditors: zjc
 * @LastEditTime: 2023-07-17 13:55:14
 * @LastEditors: zjc
 */
import qs from 'qs'
import Sign from '@/utils/sign.js'
import service from './axios'
import { adminStore } from '@/store/user.js'
import { ElMessageBox } from 'element-plus'
import { getStore, setStore } from '@/utils/store'

/**
 * 获取content-type
 * @param {String} requestType 
 * @param {String} method 
 * @param {Object} data 
 * @returns 
 */
const getType = (requestType = null, method, data) => {
  let type = '' //请求类型  requestType为表单 反之为json 默认不传 请求类型是json需要传递
  let httpData = {}
  // data.libraryId = getStore({ name: 'libraryId' }) ? getStore({ name: 'libraryId' }) : null
  let menulibrary = [
    '/library/homePage/index',
    '/message/information/index',
    '/message/notice/index',
    '/message/bannar/index',
    '/libraryAllocation/index',
    '/education/datum/index',
    '/education/video/index',
    '/education/consensus/index',
    '/education/plan/index',
    '/education/plan/add',
    '/books/bookList/index',
    '/books/bookList/bookTransfer',
    'books/bookList/bookDetail',
    '/books/Borrow/index',
    '/books/transfer/index',
    '/books/libraryAllocation/index',
    '/library/range/index',
    '/library/datum/index',
    '/library/navigation/index',
    '/library/advertisement/index',
    '/message/information/informationOperate',
    '/message/notice/noticeOperate',
    '/message/bannar/bannerOperate',
    '/education/datum/datumAdd',
    '/education/video/videoAdd',
    '/education/classify/index',
    '/education/dataClassify/index',
    '/education/consensus/consensusAdd',
    '/library/advertisement/advertisementOperate',
    '/library/navigation/navigationOperate',
    '/statistics/courseStatistics/partyCommittee',
    '/statistics/learningStatistics/partyCommittee',
    '/statistics/readStatistics/partyCommittee',
    '/dataStatistics/collectionStatistics/index',
    '/dataStatistics/learnPlanStatistics/index',  // 主题统计
  ]
  if (data.libraryId == undefined && data.libraryId == null) {
    // let flag = menulibrary.findIndex(i => i == location.hash.replace('#', ''))
    let flag = menulibrary.some(item => {
      return location.href.indexOf(item) > -1;
    })
    if (flag) {
      data.libraryId = getStore({ name: 'libraryId' }) ? getStore({ name: 'libraryId' }) : null
    } else {
      if (requestType !== 'array') {
        data.libraryId = null
      }

      setStore({ name: 'libraryId', content: null })
    }
  }
  if (requestType) {
    httpData = qs.parse(data)
    type = 'application/json'
  } else {
    httpData = qs.stringify(data)
    type = 'application/x-www-form-urlencoded'
  }
  if (requestType === 'array') {
    httpData = data
    type = 'application/json'
  }
  method = method.toLocaleLowerCase()
  return { type, httpData, methods: method }
}
/**
 * 请求
 * @param {String} method 
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} option {
 *    headers: { ...自定义请求头 }
 *    showToast : 是否展示弹窗
 * }
 * @returns 
 */
const request = (method, url, data = {}, option = { requestType: null, responseType: null, loading: false, headers: {}, showToast: true, }) => {
  const { type, methods, httpData } = getType(option.requestType, method, data)
  data.libraryId = null
  if (methods === 'post') {
    return service.post(url, httpData, { headers: { 'Content-Type': type, ...option.headers }, responseType: option.responseType, loading: option.loading, showToast: option.showToast, })
  } else if (methods === 'put') {
    return service.put(url, httpData, { headers: { 'Content-Type': type, ...option.headers }, showToast: option.showToast, })
  } else if (methods === 'get') {
    return service.get(url, {
      params: qs.parse(httpData),
      headers: { 'Content-Type': type, ...option.headers }, loading: option.loading,
      showToast: option.showToast,
    })
  } else if (methods === 'delete') {
    return service.delete(url, {
      params: qs.parse(httpData),
    },
      {
        headers: { 'Content-Type': type, ...option.headers },
        showToast: option.showToast,
      }
    )
  }
}

let isRefreshing = false
// let isRefreshing = true
/**
 * 401退出登录
 * @param {Object} error 
 */
export const jumpLogin = (error) => {
  const userStore = adminStore()
  if (!isRefreshing) {
    isRefreshing = true
    ElMessageBox.alert('会话已过期或账户在其他地方登录？', '提示', {
      confirmButtonText: '重新登录',
      type: 'warning ',
      showClose: false,
    }).then(() => {
      isRefreshing = false
      userStore.logOut()
      userStore.SET_MENU_ALL([])
      window.location.reload()
      return Promise.reject(error.response)
    }).catch((err) => {
    });
  }
}
/**
 * 白名单不需要校验401
 */
export const whiteUrl = [
  '/api/org/org/user-orgs',
  '/api/library/library/admin/libraries',
  '/api/org/permission/tree',
  '/api/org/permission',
  '/api/auth/oauth2/token',
  '/api/lms/resource',
  `/api/oms/auth`,
  '/api/org/org-auth/afterLogin'
]

let mobileApi = [
  `/api/org/unit/my`,
]

whiteUrl.push(...mobileApi);
export default request