const cacheRouter = [{
        id: 1,
        url: '/organizer/framework/index'
    }

] //缓存的路由
const noCacheRouter = [{
    parentId: 1,
    url: '/organizer/framework/department'

}] //从缓存的路由跳过来的，不清除过来的那个路由，一般为缓存的子级路由

export {
    cacheRouter, noCacheRouter
  }
  