/*
 * @Description: 书库
 * @Author: zjc
 * @Date: 2022-04-28 14:51:32
 * @LastEditTime: 2024-08-20 17:35:20
 * @LastEditors: zjc
 */
import request from '@/utils/request'
const getBookPage = (params) => request('post', '/book/org-book/org-oms/page', params, { loading: true }) //图书列表
const getBookDetail = (params) => request('post', '/book/library-book/book-info/oms/detail', params) //图书信息
const getBorrowRrecord = (params) => request('post', '/read/borrow-record/org-oms/page', params) //借阅记录列表
const transferBook = (params) => request('post', '/book/org-book/org-oms/transfer', params, { requestType: 'JSON' }) //划拨
const getBookReturn = (params) => request('post', '/read/org-oms/bookReturn', params) //归还

const getbookConfirmPage = (params) => request('post', '/book/book-confirm-record/page', params, { requestType: 'JSON', loading: true })// 购买书单分页 
const getbookPurchaseList = (params) => request('post', '/book/book-purchase-list/list', params, { requestType: 'JSON', loading: true })// 购买书单不分页 
const getPendingCnt = (params) => request('get', '/book/book-purchase-list/pending-cnt', params) //当前未提交的购书单数量统计

const getBatchModify = (params) => request('post', '/book/book-purchase-list/batch-modify', params, { requestType: 'JSON' })// 批量修改采购 
const getBatchDelete = (params) => request('post', '/book/book-purchase-list/batch-delete', params, { requestType: 'JSON' })// 批量删除
const getBatchConfirm = (params) => request('post', '/book/book-purchase-list/confirm', params, { requestType: 'JSON' })// 确认提交
const getDetail = (params) => request('post', '/book/book-confirm-record/detail', params)// 详情

const getBookInfoList = (params) => request('post', '/book/book-info/oms/bookInfoList', params, { loading: true })// 图书采购列表

const getParamPage = (params) => request('get', '/oms/param/page', params) // 获取参数设置列表

const ldpToken = () => request('get', '/book/ldp/ldpLogin') //获取馆配登录token
const getLdpOpen = () => request('get', '/book/ldp/getIsOpenLdp') //是否开通馆配
const registerLdp = () => request('get', '/book/ldp/register') //开通馆配
export const Book_API = {
  getBookPage,
  getBookDetail,
  getbookPurchaseList,
  getBorrowRrecord,
  transferBook,
  getBookReturn,
  getPendingCnt,
  getBatchModify,
  getBatchDelete,
  getBatchConfirm,
  getbookConfirmPage,
  getDetail,
  getBookInfoList,
  getParamPage,
  ldpToken,
  getLdpOpen,
  registerLdp
}