<!--
 * @Description: 分页组件
 * @Author: zjc
 * @Date: 2021-12-07 11:48:20
 * @LastEditTime: 2023-10-20 17:11:57
 * @LastEditors: ym
-->
<template>
  <div style="text-align: right">
    <el-pagination @current-change="changePage" @size-change="handleSizeChange" background
      v-model:current-page="pageData.current" :page-sizes="pageSizes"
      v-model:page-size="pageData.size" layout="total, sizes,prev, pager, next,jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script setup>
import { setStore } from '@/utils/store'
import { onBeforeUnmount } from 'vue'

const props = defineProps({
  pageData: {
    type: Object,
    default: () => { }
  },
  pageSizes: {
    type: Array,
    default: () => [10, 20, 30, 50, 70, 100, 200]
  },
  total: {
    type: Number,
    default: 0
  },
  isCache: { //是否重置页数，弹窗一般不重置，会影响列表的页码
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['hanleSize', 'hanlePage'])
const changePage = (val) => {
  emit('hanleSize', val)
  if (props.isCache) {
    setStore({ name: 'pageNum', content: val })
  }
}
const handleSizeChange = (val) => {
  emit('hanlePage', val)
}
onBeforeUnmount(() => {
  // setStore({ name: 'pageNum', content: 1 })

})

</script>