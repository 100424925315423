
/*
 * @Description: 
 * @Author: ym
 * @Date: 2023-06-15 17:12:15
 * @LastEditTime: 2024-08-12 11:03:06
 * @LastEditors: zjc
 */
// 三级本地路由
const routers =
  [
    {
      parentId: '1011',
      authIcon: "permission",
      authName: "公告管理",
      authUrl: "/message/notice/noPermission",
      id: '1016',
      children: []
    },
    {
      parentId: '1051',
      authIcon: "dashboard",
      authName: "图书馆管理员",
      authUrl: "/system/library/Administrator",
      id: '1056',
      children: []
    },
    {
      parentId: '1051',
      authIcon: "dashboard",
      authName: "开放范围",
      authUrl: "/system/library/range",
      id: '1057',
      children: []
    },
    {
      parentId: '1051',
      authIcon: "dashboard",
      authName: "角色成员",
      authUrl: "/system/role/role-member",
      id: '1054',
      children: []
    },
    {
      parentId: '1051',
      authIcon: "dashboard",
      authName: "权限范围",
      authUrl: "/system/role/role-purview",
      id: '1053',
      children: []
    },
    {
      parentId: '1003',
      authIcon: "dashboard",
      authName: "部门成员",
      authUrl: "/organizer/framework/department",
      id: '1004',
      children: [],
    },
    {
      parentId: '1011',
      authIcon: "dashboard",
      authName: "资讯管理",
      authUrl: "/message/information/informationOperate",
      id: '1013',
      children: []
    },
    {
      parentId: '1011',
      authIcon: "dashboard",
      authName: "公告管理",
      authUrl: "/message/notice/noticeOperate",
      id: '1015',
      children: []
    },
    {
      parentId: '1011',
      authIcon: "dashboard",
      authName: "轮播管理",
      authUrl: "/message/bannar/bannerOperate",
      id: '1018',
      children: []
    },
    {
      parentId: '1025',
      authIcon: "dashboard",
      authName: "轮播管理",
      authUrl: "/appSet/bannar/bannerOperate",
      id: '10333',
      children: []
    },
    {
      parentId: '1022',
      authIcon: "dashboard",
      authName: "新增学习资料",
      authUrl: "/education/datum/datumAdd",
      id: '10222',
      children: []
    },
    {
      parentId: '1023',
      authIcon: "dashboard",
      authName: "新增视频课程",
      authUrl: "/education/video/videoAdd",
      id: '10233',
      children: []
    },
    {
      parentId: '1024',
      authIcon: "dashboard",
      authName: "新增共读计划",
      authUrl: "/education/consensus/consensusAdd",
      id: '10224',
      children: []

    },
    {
      parentId: '1024',
      authIcon: "dashboard",
      authName: "新增视频课程2",
      authUrl: "/education/source/videoAdd",
      id: '10224',
      children: []

    },
    {
      parentId: '1024',
      authIcon: "dashboard",
      authName: "新增视学习资料2",
      authUrl: "/education/source/datumAdd",
      id: '10224',
      children: []

    },
    {
      parentId: '1025',
      authIcon: "dashboard",
      authName: "主题学习",
      authUrl: "/education/plan/add",
      id: '10225',
      children: []
    },
    {
      parentId: '1031',
      authIcon: "dashboard",
      authName: "馆藏转出",
      authUrl: "/books/bookList/bookTransfer",
      id: '1032',
      children: []
    },
    {
      parentId: '1031',
      authIcon: "dashboard",
      authName: "图书概况",
      authUrl: "/books/bookList/bookDetail",
      id: '1032',
      children: []
    },
    {
      parentId: '1031',
      authIcon: "dashboard",
      authName: "记录详情",
      authUrl: "/books/libraryAllocation/queryLibrary",
      id: '1034',
      children: []
    },
    {
      parentId: '10411',
      authIcon: "dashboard",
      authName: "导航管理",
      authUrl: "/library/navigation/navigationOperate",
      id: '10421',
      children: []
    },
    {
      parentId: '10411',
      authIcon: "dashboard",
      authName: "图片广告管理",
      authUrl: "/library/advertisement/advertisementOperate",
      id: '10423',
      children: []
    },
    {
      parentId: '10418',
      authIcon: "dashboard",
      authName: "课程统计",
      authUrl: "/statistics/courseStatistics/partyCommittee",
      id: '10429',
      children: []
    },
    {
      parentId: '10417',
      authIcon: "dashboard",
      authName: "学习统计",
      authUrl: "/statistics/learningStatistics/partyCommittee",
      id: '10430',
      children: []
    },
    {
      parentId: '10517',
      authIcon: "dashboard",
      authName: "共读统计",
      authUrl: "/statistics/readStatistics/partyCommittee",
      id: '10431',
      children: []
    },
    {
      parentId: '10411',
      authIcon: "dashboard",
      authName: "图书统计",
      authUrl: "/dataStatistics/bookStatistics/index",
      id: '10423',
      children: []
    },
    {
      parentId: '10225',
      authIcon: "dashboard",
      authName: "主题统计",
      authUrl: "/dataStatistics/learnPlanStatistics/index",
      id: '10433',
      children: []
    },
    {
      parentId: '10419',
      authIcon: "dashboard",
      authName: "导航管理",
      authUrl: "/system/navigation/navigationOperate",
      id: '10421',
      children: []
    },
    {
      parentId: '10411',
      authIcon: "dashboard",
      authName: "新增邀请函",
      authUrl: "/appSet/invitation/invitationAdd",
      id: '10434',
      children: []
    },
    {
      parentId: '10413',
      authIcon: "dashboard",
      authName: "新增商品",
      authUrl: "/appSet/product/prodAdd",
      id: '10435',
      children: []
    },
  ]

export {
  routers
}
