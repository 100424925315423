<!--
 * @Description: 操作提示
 * @Author: zjc
 * @Date: 2021-12-09 14:42:43
 * @LastEditTime: 2023-04-17 18:49:14
 * @LastEditors: zjc
-->
<template>
  <div class="tips">

    <el-alert :type="tipType" :closable="false">
      <div class="alert-title">
        <el-icon class="alert-icon">
          <Warning />
        </el-icon>
        <span>{{title}}</span>
        <el-icon class="alert-open" @click="isOpen = !isOpen" style="margin-left:6px">
          <ArrowDown v-if="!isOpen" />
          <ArrowUp v-else />
        </el-icon>
      </div>
      <el-collapse-transition>
        <div v-html="description" v-if="isOpen" class="description"></div>
      </el-collapse-transition>
    </el-alert>
  </div>
</template>
<script setup>
/**
 * @param {String} title 提示语标题 
 * @param {String} description 提示语描述 使用模板字符串
 * @param {String} tipType 类型  success --成功 info --提示 warning --警告 error --错误
 * 
 */
import { ref } from 'vue'
const isOpen = ref(true)
const props = defineProps({
  title: {
    type: String,
    default: '操作提示'
  },
  description: {
    type: String
  },
  tipType: {
    type: String,
    default: 'error'
  },
})
</script>
<style lang="scss" scoped>
.tips {
  margin-bottom: 10px;
  ::v-deep .el-alert {
    padding: 10px 16px;
    border-radius: 7px;
  }
  ::v-deep .el-alert .el-alert__description {
    margin: 0;
  }
  ::v-deep .el-alert--info.is-light {
    background: rgba(0, 0, 0, 0.04);
  }
  ::v-deep .el-alert--error.is-light .el-alert__description {
    color: $--color-primary;
  }
}
.description {
  line-height: 24px;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 18px;
  color: $--color-primary;
}
.alert-title {
  display: flex;
  align-items: center;
  .alert-icon {
    font-size: 13px;
    color: $--color-primary;
    margin-right: 6px;
  }
  .alert-open {
    font-size: 14px;
    color: $--color-primary;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>