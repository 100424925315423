<!--
 * @Description: 
 * @Author: zjc
 * @Date: 2021-12-07 22:41:39
 * @LastEditTime: 2023-06-19 11:05:33
 * @LastEditors: zjc
-->
<template>
  <div class="basic-container" id="basic-container" ref="basic">
    <el-card :class="contentScreen?'fullScreen':''">
      <div class="mod">
        <slot></slot>
      </div>

    </el-card>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { commonStore } from '@/store/common'
const height = ref(null)
const store = commonStore()
const contentScreen = computed(() => store.getContentScreen)
const setFullscreen = () => {
  store.CONTENT_FULLSCREN()
}
onMounted(() => {
  // document.addEventListener('keyup', (e) => {
  //   if (e.keyCode == 27) {
  //     if (contentScreen) {
  //       setFullscreen()
  //     }
  //   }
  // })
})
onUnmounted(() => {
  // document.removeEventListener('keyup', (e) => { })
})
</script>

<style lang="scss">
.basic-container {
  height: 100%;
  box-sizing: border-box;
  // margin: 0px 20px 20px;

  .el-card {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    overflow: auto;
    .el-card__body {
      padding: 0px 20px;
    }
    .mod {
      position: relative;
    }
  }

  &:first-child {
    padding-top: 0;
  }
}
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: #fff;
  padding: 10px;
  overflow: auto;
  width: 100%;
  margin: 0;
  z-index: 999999;
}
</style>