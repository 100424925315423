/*
 * @Description: 
 * @Author: zjc
 * @Date: 2022-05-05 15:14:46
 * @LastEditTime: 2023-10-17 15:52:51
 * @LastEditors: xlm
 */
const defaultBook = new URL('../assets/img/defaultBook.png', import.meta.url).href // 默认图书封面
const defaultVideoCover = new URL('../assets/img/defaultVideo.png', import.meta.url).href // 默认图书封面
const defaultLibrary = new URL('../assets/img/library/library.png', import.meta.url).href // 默认图书馆封面
const defaultLogo = new URL('../assets/img/library_default_icon.png', import.meta.url).href // 默认图书封面
const defaultAvatar = new URL('../assets/img/icon-user.png', import.meta.url).href // 默认头像
const bookDomain = 'https://cloud-library-default.elibr.cn/'
const importExcel = () => {
  window.location.href = 'http://cloud-library-default.elibr.cn/%E4%BA%91%E5%9B%BE%E4%B9%A6%E9%A6%86%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
}
const monthList = [
  { label: '1月', value: '1' },
  { label: '2月', value: '2' },
  { label: '3月', value: '3' },
  { label: '4月', value: '4' },
  { label: '5月', value: '5' },
  { label: '6月', value: '6' },
  { label: '7月', value: '7' },
  { label: '8月', value: '8' },
  { label: '9月', value: '9' },
  { label: '10月', value: '10' },
  { label: '11月', value: '11' },
  { label: '12月', value: '12' },
]

let defaultCompanyName = '厦门正观易知书业云科技有限公司'

export {
  defaultLogo,
  defaultBook,
  defaultAvatar,
  importExcel,
  monthList,
  bookDomain,
  defaultLibrary,
  defaultCompanyName,
  defaultVideoCover,
}