/*
 * @Description: 公共hooks
 * @Author: zjc
 * @Date: 2022-08-23 09:31:58
 * @LastEditTime: 2023-06-15 14:21:35
 * @LastEditors: zjc
 */
import { useRouter } from 'vue-router'

const useJump = (route, type = 'push') => {
  const router = useRouter()
  const jumpPage = (route, type = 'push') => {
    if (type === 'replace') {
      router.replace(route);
    } else {
      router.push(route);
    }
  }
  const goBack = (route, type = 'push') => {
    router.go(-1)
  }
  const locationPath = (route) => {
    window.location.href = route
  }

  const defalutBook = new URL('../assets/img/defaultBook.png', import.meta.url).href
  return {
    jumpPage,
    locationPath,
    goBack,
    defalutBook,
  }
}
export default useJump