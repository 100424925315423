/*
 * @Description:公共
 * @Author: zjc
 * @Date: 2021-12-07 11:52:29
 * @LastEditTime: 2023-04-28 11:55:28
 * @LastEditors: zjc
 */
import { setStore, getStore } from '@/utils/store'
import { defineStore } from 'pinia'
export const commonStore = defineStore('common', {
  state: () => {
    return {
      isFullScreen: false,
      contentScreen: false,
      isCollapse: false,
      tableLoading: false,
      isScreen: null,
      title: getStore({ name: 'routeTitle' }) || '',
      activeIndex: null,
      isSearch: true,
      crumbsList: [],//面包屑列表
      isOpen: null,
    }
  },
  getters: {
    collapse: state => state.isCollapse,
    getTableLoading: state => state.tableLoading,
    getTitle: state => state.title,
    getIsScreen: state => state.isScreen,
    getActiveIndex: state => state.activeIndex,
    getIsFullScreen: state => state.isFullScreen,
    getContentScreen: state => state.contentScreen,
    getCrumbsList: state => state.crumbsList,
    getIsOpen: state => state.isOpen,
  },
  actions: {
    // 自定义导航栏
    UPDATE_CRUMBS (data) {
      this.SET_CRUMBS(data)
    },
    OPEN_LIBRARY (bool) {
      this.isOpen = bool
    },
    // 收缩菜单栏
    UPDATES_IDEBAR_FOLD (bool) {
      this.isCollapse = !this.isCollapse
    },
    // 列表表格loading
    SET_TABLE_LOADING (bool) {
      this.tableLoading = bool
    },
    SET_TITLE (routeTitle) {
      this.title = routeTitle
      setStore({ name: 'routeTitle', content: routeTitle })
    },
    SET_SCREEN (val) {
      this.isScreen = val
      this.isScreen <= 1 ? this.isCollapse = true : this.isCollapse = false
    },
    // 全屏
    SET_FULLSCREN () {
      this.isFullScreen = !this.isFullScreen
    },
    CONTENT_FULLSCREN () {
      this.contentScreen = !this.contentScreen
    },
    SET_ACTIVE_INDEX (val) {
      this.activeIndex = val
    },
    SHOW_SEARCH (bool) {
      this.isSearch = bool
    },
    SET_CRUMBS (list) {
      this.crumbsList = list
    },
  },
})