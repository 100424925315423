<!--
 * @Description: 
 * @Author: ym
 * @Date: 2023-02-15 16:04:09
 * @LastEditTime: 2023-02-15 16:13:31
 * @LastEditors: ym
-->
<template>
  <div class="line" :style="{height:height}">

  </div>
</template>
<script setup>
const props = defineProps({
  height: {
    type: String,
    default: '11px'
  }
})
const { height } = props
</script>
<style lang="scss" scoped>
.line {
  background-color: #f5f5f5;
  width: calc(100% + 40px);
  margin-left: -20px;
}
</style>